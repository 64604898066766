import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from './lib/vue-axios'
import store from './store'
import i18n from './translations'
import vuetify from './plugins/vuetify'
import '@fortawesome/fontawesome-free/css/all.css'
import VueDayjs from 'vue-dayjs-plugin'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { AppStorage, listenForUpdateUrlMessage, analyticsModule } from 'cuf-expo-bridge'
import VueNumeric from 'vue-numeric'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import versionChecker from './lib/version-checker'

Vue.config.productionTip = false

// Initialize version checker to automatically refresh when new version is detected
versionChecker.init({
  interval: 2 * 60 * 1000, // Check every 2 minutes
  autoReload: true,
  onUpdateDetected: (versionInfo) => {
    console.log(`New version available: ${versionInfo.version}`)
    // You could also show a notification to the user here if desired
  }
})

listenForUpdateUrlMessage(({ fullpath }) => {
  router.push(fullpath)
})

router.beforeEach((to, from, next) => {
  let mainTitle = 'Panel klienta'

  if (i18n.locale === 'ro' || location.host.includes('.ro')) {
    mainTitle = 'Panoul clientului'
  } else if (i18n.locale === 'bg' || location.host.includes('.bg')) {
    mainTitle = 'Клиентски панел'
  }

  if (to.fullPath === '/') {
    if (i18n.locale === 'ro' || location.host.includes('.ro')) {
      window.document.title = mainTitle + ' | ' + to.meta.title.ro
    } else if (i18n.locale === 'bg' || location.host.includes('.bg')) {
      window.document.title = mainTitle + ' | ' + to.meta.title.bg
    } else if (i18n.locale === 'pl' || location.host.includes('.euro-tax.pl')) {
      window.document.title = mainTitle + ' | Euro-tax.com'
    } else {
      window.document.title = mainTitle + ' | CUF'
    }
  } else {
    if (to.meta && to.meta.title) {
      if (i18n.locale === 'ro' || location.host.includes('.ro')) {
        window.document.title = mainTitle + ' | ' + to.meta.title.ro
      } else if (i18n.locale === 'bg' || location.host.includes('.bg')) {
        window.document.title = mainTitle + ' | ' + to.meta.title.bg
      } else {
        window.document.title = mainTitle + ' | ' + to.meta.title.pl
      }
    } else {
      window.document.title = mainTitle
    }
  }

  if (to.fullPath.includes('/redirect-to-app')) {
    // allow redirect to native app
    next()
  } else if (to.fullPath.includes('?login=') || (to.fullPath.includes('?type=')) || to.fullPath.includes('/select-language')) {
    next()
  } else if (to.fullPath.includes('.well-known/assetlinks.json')) {
    next()
  } else if (to.fullPath.includes('.well-known/apple-app-site-association')) {
    next()
  } else if (to.fullPath !== '/' && to.fullPath !== '/remind_password' && to.fullPath !== '/sign_up' && !to.fullPath.includes('/verify/') && store.state.auth.client == null) {
    next({ path: '/' })
  } else if (to.fullPath !== '/active_account' && !to.fullPath.includes('/verify/') && store.state.auth.client !== null && !store.state.auth.client.registered) {
    next({ path: '/active_account' })
  } else if (to.fullPath !== '/reset_password' && to.fullPath !== '/active_account' && !to.fullPath.includes('/verify/') && store.state.auth.client !== null && store.state.auth.client.reset_password) {
    next({ path: '/reset_password' })
  } else {
    next()
  }
})

router.afterEach(to => {
  analyticsModule.logEvent({ name: 'screen_view', params: { screen_name: to.name, screen_class: to.name, page_location: to.fullPath, page_title: document.title } })
})

Vue.use(VueDayjs)
Vue.use(VueNumeric)
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY })
Vue.component('vue-phone-number-input', VuePhoneNumberInput)
Vue.component('vue-numeric', VueNumeric)

Vue.filter('uppercase', function (value) {
  if (!value) return ''
  return value.toUpperCase()
})

Vue.axios.interceptors.response.use((response) => { // intercept the global error
  store.dispatch('toggleLoading', false)
  return response
}, function (error) {
  if ((error.response.status === 400 || error.response.status === 401 || error.response.status === 403) && !error.request.responseURL.includes('/auth/')) {
    store.dispatch('toggleLoading', false)
    AppStorage.remove('token').then(() => {
      store.dispatch('logout')
      router.push('/')
    })
  } else if (error.response.status === 404) {
    store.dispatch('toggleLoading', false)
    router.push('/not_found')
  } else {
    return Promise.reject(error)
  }
})

AppStorage.get('token')
  .then((token) => store.dispatch('login', token))
  .then(() => {
    new Vue({
      router,
      axios,
      store,
      i18n,
      vuetify,
      created () {
        const html = document.documentElement // returns the html tag
        let lang = 'pl'

        if (i18n.locale === 'ro' || location.host.includes('.ro')) {
          lang = 'ro'
        } else if (i18n.locale === 'bg' || location.host.includes('.bg')) {
          lang = 'bg'
        }

        html.setAttribute('lang', lang)
      },
      render: h => h(App)
    }).$mount('#app')
  })
