const state = {
  loading: false
}

const getters = {
  loading (state) {
    return state.loading
  }
}

const mutations = {
  toggleLoading (state, toggle = false) {
    state.loading = toggle
  }
}

const actions = {
  toggleLoading ({ commit }, toggle = true) {
    commit('toggleLoading', toggle)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
