const state = {
  showUpdateContactDetailsModal: false,
  showUpdateAccountNumberMultipleDocumentsModal: false,
  showUserProfile: false,
  showChangePassword: false,
  showTerms: false
}

const getters = {
  showUpdateContactDetailsModal (state) {
    return state.showUpdateContactDetailsModal
  },
  showUpdateAccountNumberMultipleDocumentsModal (state) {
    return state.showUpdateAccountNumberMultipleDocumentsModal
  },
  showUserProfile (state) {
    return state.showUserProfile
  },
  showChangePassword (state) {
    return state.showChangePassword
  },
  showTerms (state) {
    return state.showTerms
  }
}

const mutations = {
  displayShowUpdateContactDetailsModal (state) {
    state.showUpdateContactDetailsModal = true
  },
  closeShowUpdateContactDetailsModal (state) {
    state.showUpdateContactDetailsModal = false
  },
  displayShowUpdateAccountNumberMultipleDocumentsModal (state) {
    state.showUpdateAccountNumberMultipleDocumentsModal = true
  },
  closeShowUpdateAccountNumberMultipleDocumentsModal (state) {
    state.showUpdateAccountNumberMultipleDocumentsModal = false
  },
  displayShowUserProfile (state) {
    state.showUserProfile = true
  },
  closeShowUserProfile (state) {
    state.showUserProfile = false
  },
  displayShowChangePassword  (state) {
    state.showChangePassword = true
  },
  closeShowChangePassword (state) {
    state.showChangePassword = false
  },
  displayShowTerms (state) {
    state.showTerms = true
  },
  closeShowTerms (state) {
    state.showTerms = false
  }
}

const actions = {
  displayShowUpdateContactDetailsModal ({ commit }) {
    commit('displayShowUpdateContactDetailsModal')
  },
  closeShowUpdateContactDetailsModal ({ commit }, documentId) {
    commit('closeShowUpdateContactDetailsModal', documentId)
  },
  displayShowUpdateAccountNumberMultipleDocumentsModal ({ commit }) {
    commit('displayShowUpdateAccountNumberMultipleDocumentsModal')
  },
  closeShowUpdateAccountNumberMultipleDocumentsModal ({ commit }, documentId) {
    commit('closeShowUpdateAccountNumberMultipleDocumentsModal', documentId)
  },
  displayShowUserProfile ({ commit }) {
    commit('displayShowUserProfile')
  },
  closeShowUserProfile ({ commit }, documentId) {
    commit('closeShowUserProfile', documentId)
  },
  displayShowChangePassword ({ commit }) {
    commit('displayShowChangePassword')
  },
  closeShowChangePassword ({ commit }, documentId) {
    commit('closeShowChangePassword', documentId)
  },
  displayShowTerms ({ commit }) {
    commit('displayShowTerms')
  },
  closeShowTerms ({ commit }, documentId) {
    commit('closeShowTerms', documentId)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
