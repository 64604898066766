<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" :fullscreen="isScreenSmall ? true : false" max-width="1200px">
      <v-card>
        <v-card-title>
          <div class="container text-right">
            <v-menu
                bottom
                right
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="close-menu"
                  @click="cancel()"
                >
                  <v-icon class="close-icon mt-6 mr-6 mt-md-0 mr-md-0">mdi-close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </div>
          <h2 v-if="!successScreen" class="semibold-font">{{ $t('change_password') }}</h2>
          <p v-if="!successScreen" class='description mt-4'>{{ $t('setNewPassword') }}</p>
        </v-card-title>
        <v-card-text v-if="successScreen">
          <div class="text-center">
            <div class="circle-gradient-big">
              <i class="fas fa-check"></i>
            </div>
            <h3 class="semibold-font mt-6">{{ $t('change_password_title') }}</h3>
            <button
              class="main-button main-button-fixed mt-12"
              @click="cancel()"
            >{{ $t('back_to_panel') }}</button>
          </div>
        </v-card-text>
        <v-card-text v-if="!successScreen" >
          <v-form @submit.prevent class="modal-form">
            <v-container>
              <v-row>
                <v-col cols="12" class="mt-1 mt-lg-0">
                  <v-text-field
                    :autocomplete="Math.random().toString(36)"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    @change="checkCurrentPassword()"
                    v-model="currentPassword"
                    :label="$t('currentPassword')"
                    class="outlined__input login__input"
                    outlined
                    required
                  ></v-text-field>

                  <p v-if="wrongCurrentPassword" class="v-messages error--text">{{ $t('wrongPassword') }}</p>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :autocomplete="Math.random().toString(36)"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    @click:append="show2 = !show2"
                    @change="checkPassword()"
                    v-model="password"
                    :label="$t('setNewPasswordInput')"
                    class="outlined__input login__input"
                    outlined
                    required
                  ></v-text-field>

                  <div v-if="passwordLengthError" class="v-messages error--text">{{ $t('shortPasswordError') }}</div>
                  <div v-if="passwordNumberError" class="v-messages error--text">{{ $t('passwordNumberError') }}</div>
                  <div v-if="passwordUppsercaseError" class="v-messages error--text">{{ $t('passwordUppsercaseError') }}</div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :autocomplete="Math.random().toString(36)"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show3 ? 'text' : 'password'"
                    @click:append="show3 = !show3"
                    @change="checkConfirmationPassword()"
                    v-model="passwordConfirmation"
                    :label="$t('setAgainNewPasswordInput')"
                    class="outlined__input login__input"
                    outlined
                    required
                  ></v-text-field>

                  <div v-if="checkSameConfirmationPassword && passwordConfirmation" class="v-messages error--text">{{ $t('samePasswordsError') }}</div>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions v-if="!successScreen" class="row">
          <v-spacer></v-spacer>
          <v-col cols="12" lg="6" class="text-center">
            <button
              :disabled="currentPassword == password || !password || !passwordConfirmation || wrongCurrentPassword || passwordLengthError || passwordNumberError || passwordUppsercaseError || checkSameConfirmationPassword"
              class="main-button main-button-fixed"
              @click="changePassword()"
            >{{ $t('change_password_button') }}</button>
          </v-col>
          <v-col cols="12" lg="6" class="text-center text-lg-right grey-link">
            <a
              @click="cancel()"
            > {{ $t('cancel') }}
            </a>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'changePasswordModal',
  data () {
    return {
      dialog: true,
      successScreen: false,
      currentPassword: null,
      password: null,
      passwordConfirmation: null,
      show1: false,
      show2: false,
      show3: false,
      wrongCurrentPassword: false,
      passwordLengthError: false,
      passwordNumberError: false,
      passwordUppsercaseError: false,
      samePasswordsError: false
    }
  },
  computed: {
    ...mapGetters(['currentClient']),

    checkSameConfirmationPassword () {
      return this.password !== this.passwordConfirmation
    },

    isScreenSmall () {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
    }
  },
  methods: {
    changePassword () {
      if (this.password === this.passwordConfirmation) {
        this.$http.put(`/client_accounts/${this.currentClient.id}/change_password/`, {
          data: { password: this.password }
        })
          .then(response => {
            this.successScreen = true
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    checkConfirmationPassword () {
      this.samePasswordsError = this.password !== this.passwordConfirmation
    },
    checkPassword () {
      if (this.password.length > 9) {
        this.passwordLengthError = false
      } else {
        this.passwordLengthError = true
      }

      this.passwordNumberError = !/\d/.test(this.password)
      this.passwordUppsercaseError = !/[A-Z]/.test(this.password)
      this.samePasswordsError = this.password !== this.passwordConfirmation
    },
    checkCurrentPassword () {
      this.$http.put(`/client_accounts/${this.currentClient.id}/current_password/`, {
        data: { password: this.currentPassword }
      })
        .then(response => {
          this.wrongCurrentPassword = !response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    cancel () {
      this.$store.dispatch('closeShowChangePassword')
    }
  }
}
</script>

<style lang="scss">
  .error-notice {
    font-size: 80%;
    color: red;
  }

  .success-notice {
    font-size: 80%;
    color: green;
  }
</style>
