<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent :fullscreen="isScreenSmall ? true : false" max-width="1200px">
      <v-card>
        <v-card-title>
          <div v-if="readOnly" class="container text-right">
            <v-menu
                bottom
                right
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="close-menu"
                  @click="cancel()"
                >
                  <v-icon class="close-icon mt-6 mr-6 mt-md-0 mr-md-0">mdi-close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </div>
          <div v-if="!readOnly" class="container text-right">
            <v-menu
                bottom
                right
              >
            </v-menu>
          </div>
          <div class="container text-center mt-2">
            <img class="logo" :src="logoSvg" />
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col v-if="!readOnly" cols="6" class="text-left">
                <h1 class="semibold-font mt-6 mb-6 stay-in-contact-title">{{ $t('stay_in_contact') }}</h1>
              </v-col>

              <v-col v-if="!readOnly" cols="6" class="text-center pl-0 mt-6">
                <img src="@/assets/phone-notifications.svg">
              </v-col>
            </v-row>
            <v-row class="mt-4 mt-lg-2">
              <v-col v-if="!readOnly" cols="12" class="text-left">
                <div style="word-break: break-word">{{ $t('marketing_consent_intro1') }}</div>
              </v-col>
            </v-row>
            <v-row class="mt-3 mt-lg-2">
              <v-col v-if="!readOnly" cols="12" class="text-left">
                <div style="word-break: break-word">{{ $t('marketing_consent_intro2') }}</div>
              </v-col>
            </v-row>
            <v-row class="mt-3 mt-lg-2">
              <v-col v-if="!readOnly" cols="12" class="text-left">
                <div style="word-break: break-word">{{ $t('marketing_consent_intro3') }}</div>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col v-if="!readOnly" cols="12" class="text-left">
                <v-checkbox
                  class="mt-0"
                  v-model="data.push_agreement"
                >
                  <template v-slot:label>
                    <strong>{{ $t('push_notifications') }}</strong>
                  </template>
                </v-checkbox>
                <v-checkbox
                  class="mt-0"
                  v-model="data.mail_agreement"
                >
                  <template v-slot:label>
                    <strong>{{ $t('email_notifications') }}</strong>
                  </template>
                </v-checkbox>
                <v-checkbox
                  class="mt-0"
                  v-model="data.messengers_agreement"
                >
                  <template v-slot:label>
                    <v-row class="mt-0">
                      <v-col class="pb-0">
                        <div class="pb-0">
                          <strong>{{ $t('phone_notifications') }}</strong>
                        </div>
                      </v-col>
                      <v-col cols="12" class="text-left">
                        <div style="display: block;">
                          {{ $t('phone_notifications_info') }}
                        </div>
                      </v-col>
                    </v-row>
                  </template>
                </v-checkbox>
                <v-checkbox
                  class="mt-0"
                  v-model="data.sms_mms_agreement"
                >
                  <template v-slot:label>
                    <v-row class="mt-0">
                      <v-col class="pb-0">
                        <div class="pb-0">
                          <strong>{{ $t('sms_notifications') }}</strong>
                        </div>
                      </v-col>
                      <v-col cols="12" class="text-left">
                        <div style="display: block;">
                          {{ $t('sms_notifications_info') }}
                        </div>
                      </v-col>
                    </v-row>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row class="mt-3 mt-lg-2">
              <v-col v-if="!readOnly" cols="12" class="text-left">
                <div style="word-break: break-word">{{ $t('share_data') }}</div>
              </v-col>
            </v-row>
            <v-radio-group inline class="inline-radio-buttons" v-model="data.commercial_agreement">
              <v-radio class="inline-block" :label="$t('true')" :value="true"></v-radio>
              <v-radio class="inline-block ml-12" :label="$t('false')" :value="false"></v-radio>
            </v-radio-group>

            <v-col cols="12" class="text-center">
              <button class="main-button main-button-fixed" @click="agreeTerms()">
                {{ $t('accept') }}
              </button>
            </v-col>

            <v-col v-if="!readOnly" cols="12" class="text-left pl-0 grey-text">
              <div style="word-break: break-word" v-html="adminInfo"></div>
            </v-col>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'marketingConsentModal',
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: true,
      data: {
        sms_mms_agreement: false,
        mail_agreement: false,
        push_agreement: false,
        messengers_agreement: false,
        commercial_agreement: false
      }
    }
  },
  computed: {
    ...mapGetters(['currentClient']),

    adminInfo () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return 'Administratorul datelor tale cu caracter personal este S.C. Euro-tax.ro Servicii Fiscale Emigranţi S.R.L. cu sediul în Otopeni Str. Drumul Gării Odăi, Nr 1A, Clădirea Airport Plaza, Et 2, Jud Ilfov, Cod poștal 719182. (mai departe: „Administratorul").Administratorul a desemnat un Responsabil cu Protecția Datelor care supraveghează corectitudinea prelucrării datelor cu caracter personal, putând fi contactat la adresa de e-mail: data.protection@euro-tax.ro sau prin trimiterea unui mesaj pe adresa de poștă electronică a administratorului. <strong><a target="_blank" href="https://euro-tax.com/ro/politica-de-confidentialitate/">Mai multe informații</a></strong>'
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return 'Администратор на вашите лични данни е „ЕВРО-ТАКС.БГ” ООД, със седалище и адрес на управление в гр. София, ж.к. Младост 1, бул. „Климент Охридски“ 1А – етаж 3. Администраторът е назначил инспектор по защита на данните, който следи за правилността на обработването на лични данни и с когото можете да се свържете на имейл адрес: dldz@euro-tax.bg, или като пишете на пощенския адрес на администратора. <strong><a target="_blank" href="https://euro-tax.com/bg/politika-za-poveritelnost/">Повече информация</a></strong>'
      } else if (this.$i18n.locale === 'pl' && (this.currentClient && this.currentClient.agreement_with === 'CUF')) {
        return 'Administratorem Twoich danych osobowych jest CUF Sp. z o.o. z siedzibą w Poznaniu (61-626), przy ul. Szelągowskiej 25/2-3 (dalej: „Administrator”). Administrator wyznaczył Inspektora Ochrony Danych nadzorującego prawidłowość przetwarzania danych osobowych, z którym można skontaktować się pod adresem e-mail: odo@cuf.com.pl lub pisząc na adres pocztowy administratora.'
      } else {
        return 'Administratorem Twoich danych osobowych jest EURO-TAX.PL ZWROT PODATKU S.A. z siedzibą we Wrocławiu (53-659), ul. Władysława Sikorskiego 26 (dalej: „Administrator”). Administrator wyznaczył Inspektora Ochrony Danych nadzorującego prawidłowość przetwarzania danych osobowych, z którym można skontaktować się pod adresem e-mail: odo@euro-tax.pl lub pisząc na adres pocztowy administratora.'
      }
    },

    isScreenSmall () {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
    },

    logoSvg () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return require('@/assets/euro-tax.ro.svg')
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return require('@/assets/euro-tax.bg.svg')
      } else if (location.host.includes('.euro-tax.pl')) {
        if (this.$i18n.locale === 'ro') {
          return require('@/assets/euro-tax.ro.svg')
        } else if (this.$i18n.locale === 'bg') {
          return require('@/assets/euro-tax.bg.svg')
        } else if (this.currentClient && this.currentClient.agreement_with === 'CUF') {
          return require('@/assets/cuf-logo.svg')
        } else {
          return require('@/assets/euro-tax.pl.svg')
        }
      } else {
        return require('@/assets/cuf-logo.svg')
      }
    }
  },
  methods: {
    agreeTerms () {
      // Set marketing_agreement to true if any individual agreement is true
      const marketingAgreement = this.data.push_agreement ||
                                  this.data.mail_agreement ||
                                  this.data.messengers_agreement ||
                                  this.data.sms_mms_agreement ||
                                  this.data.commercial_agreement

      this.$http.put(`/client_accounts/${this.currentClient.id}/marketing_consent/`, {
        data: {
          push_agreement: this.data.push_agreement,
          mail_agreement: this.data.mail_agreement,
          messengers_agreement: this.data.messengers_agreement,
          sms_mms_agreement: this.data.sms_mms_agreement,
          commercial_agreement: this.data.commercial_agreement,
          marketing_agreement: marketingAgreement
        }
      })
        .then(() => this.$http.get(`/client_accounts/${this.currentClient.id}/refresh_token/`))
        .then(response => {
          localStorage.token = response.data.token
          this.$store.dispatch('login', response.data.token)
        })
        .then(() => {
          this.dialog = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    cancel () {
      this.$store.dispatch('closeShowTerms')
    },
    logout () {
      this.$http.delete('auth')
        .then(response => {
          delete localStorage.token
          this.$store.dispatch('logout')
          this.$router.push('/login?type=logout')
        })
    }
  }
}
</script>

<style lang="scss">
  .hr-line {
    margin-bottom: -15px;
  }

  .stay-in-contact-title {
    line-height: 2.375rem;
  }

  .inline-radio-buttons {
    display: flex;
    justify-content: space-between;

    .v-input--radio-group__input {
      display: inline-block;
    }
  }
</style>
