import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: {
      title: {
        ro: 'Autentificare',
        bg: 'Влизам',
        pl: 'Zaloguj sie'
      }
    },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      title: {
        ro: 'Începe',
        bg: 'Започнете',
        pl: 'Start'
      }
    },
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: {
        ro: 'Autentificare',
        bg: 'Влизам',
        pl: 'Zaloguj sie'
      }
    },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/sign_up',
    name: 'signUp',
    meta: {
      title: {
        ro: 'Înregistrare',
        bg: 'Регистрация',
        pl: 'Rejestracja'
      }
    },
    component: () => import('../views/signUp.vue')
  },
  {
    path: '/active_account',
    name: 'ActiveAccount',
    meta: {
      title: {
        ro: 'Activare cont',
        bg: 'Активиране на акаунт',
        pl: 'Aktywacja konta'
      }
    },
    component: () => import('../views/ActiveAccount.vue')
  },
  {
    path: '/documents',
    name: 'Documents',
    meta: {
      title: {
        ro: 'Cazurile mele',
        bg: 'моите случаи',
        pl: 'Moje sprawy'
      }
    },
    component: () => import('../views/Documents.vue')
  },
  {
    path: '/newDocument',
    name: 'newDocument',
    meta: {
      title: {
        ro: 'Cazurile mele',
        bg: 'моите случаи',
        pl: 'Moje sprawy'
      }
    },
    component: () => import('../views/newDocument.vue')
  },
  {
    path: '/reset_password',
    name: 'resetPassword',
    meta: {
      title: {
        ro: 'Reseteaza parola',
        bg: 'нулиране на паролата',
        pl: 'Zresetuj hasło'
      }
    },
    component: () => import('../views/resetPassword.vue')
  },
  {
    path: '/verify/:token',
    name: 'verifyResetToken',
    meta: {
      title: {
        ro: 'verificați jetonul de resetare',
        bg: 'проверете нулирането на маркера',
        pl: 'Zweryfikuj token resetowania'
      }
    },
    component: () => import('../views/verifyResetToken.vue')
  },
  {
    path: '/remind_password',
    name: 'remindPassword',
    meta: {
      title: {
        ro: 'Reamintesc parola',
        bg: 'напомняне на парола',
        pl: 'Przypomnij hasło'
      }
    },
    component: () => import('../views/remindPassword.vue')
  },
  {
    path: '/documents/:documentId',
    name: 'showDocument',
    component: () => import('../views/showDocument.vue'),
    children: [
      {
        path: '/documents/:documentId/general',
        name: 'documentGeneral',
        meta: {
          title: {
            ro: 'Detalii despre caz',
            bg: 'Подробности за случая',
            pl: 'Szczegóły sprawy'
          }
        },
        component: () => import('../views/document/General.vue')
      },
      {
        path: '/documents/:documentId/edit',
        name: 'documentEdit',
        meta: {
          title: {
            ro: 'Editeaza cazul',
            bg: 'Редактиране на случая',
            pl: 'Edytuj sprawę'
          }
        },
        component: () => import('../views/newDocument.vue')
      },
      {
        path: '/documents/:documentId/history',
        name: 'documentHistory',
        meta: {
          title: {
            ro: 'Istorie',
            bg: 'История',
            pl: 'Historia'
          }
        },
        component: () => import('../views/document/History.vue')
      },
      {
        path: '/documents/:documentId/attachments',
        name: 'documentAttachments',
        meta: {
          title: {
            ro: 'Atașamente',
            bg: 'Прикачени файлове',
            pl: 'Załączniki'
          }
        },
        component: () => import('../views/document/Attachments.vue')
      }
    ]
  },
  {
    path: '/messages',
    name: 'Messages',
    meta: {
      title: {
        ro: 'Mesaje',
        bg: 'Съобщения',
        pl: 'Wiadomości'
      }
    },
    component: () => import('../views/messages.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      title: {
        ro: 'a lua legatura',
        bg: 'Контакт',
        pl: 'Kontakt'
      }
    },
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/account',
    name: 'Account',
    meta: {
      title: {
        ro: 'Cont',
        bg: 'Сметка',
        pl: 'Konto'
      }
    },
    component: () => import('../views/Account.vue')
  },
  {
    path: '/select-language',
    name: 'SelectLanguage',
    component: () => import('../views/SelectLanguage.vue')
  },
  {
    path: '/redirect-to-app',
    name: 'RedirectToApp',
    component: () => import('../views/RedirectToApp.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
