
import { AppStorage } from 'cuf-expo-bridge'

const tryRecoveryUTMFromStorage = async () => {
  const trackingDataKey = 'trackingUtm'

  try {
    const trackingDataString = await AppStorage.get(trackingDataKey)
    const trackingData = JSON.parse(trackingDataString)

    AppStorage.remove(trackingDataKey)

    return extractUTM(trackingData)
  } catch {
    // do nothing
    return null
  }
}

const tryRecoveryUTMFromQueryParams = async () => {
  try {
    const searchParams = new URLSearchParams(window.location.search)
    const data = Object.fromEntries(searchParams)

    return extractUTM(data)
  } catch {
    return null
  }
}

const extractUTM = (data) => {
  return {
    source: data.utm_source || undefined,
    medium: data.utm_medium || undefined,
    campaign: data.utm_campaign || undefined,
    term: data.utm_term || undefined,
    content: data.utm_content || undefined,
    id: data.utm_id || undefined,
    aclid: data.aclid || undefined,
    cp1: data.cp1 || undefined
  }
}

export const recoverUtm = async () => {
  let data = await tryRecoveryUTMFromStorage()

  if (!data) {
    data = await tryRecoveryUTMFromQueryParams()
  }

  return data
}
