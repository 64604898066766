<template>
  <v-snackbar
    v-model="show"
    :timeout="timeout"
    color="info"
    bottom
    right
  >
    <div class="d-flex align-center">
      <span>{{ $t('update.available') }}</span>
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="refreshPage"
        class="ml-2"
      >
        {{ $t('update.refresh') }}
      </v-btn>
      <v-btn
        icon
        @click="show = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import versionChecker from '@/lib/version-checker'

export default {
  name: 'UpdateNotification',

  data () {
    return {
      show: false,
      timeout: -1, // infinity
      newVersion: null
    }
  },

  created () {
    // Initialize version checker
    versionChecker.init({
      interval: 2 * 60 * 1000, // Check every 2 minutes
      autoReload: false, // Don't auto reload, let user choose
      onUpdateDetected: (versionInfo) => {
        this.newVersion = versionInfo
        this.show = true
      }
    })
  },

  methods: {
    refreshPage () {
      window.location.reload(true)
    }
  }
}
</script>
