import VueI18n from 'vue-i18n'
import Vue from 'vue'
import bg from './bg.json'
import ro from './ro.json'
import pl from './pl.json'
import store from './../store'

Vue.use(VueI18n)

let filterLocale

if (store.state.auth.user) {
  filterLocale = store.state.auth.user.locale
} else {
  filterLocale = 'pl'
}

export default new VueI18n({
  locale: filterLocale,
  messages: {
    pl,
    bg,
    ro
  }
})
