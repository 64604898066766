<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent :fullscreen="isScreenSmall ? true : false" max-width="1200px">
      <v-card>
        <v-card-title>
          <div v-if="readOnly" class="container text-right">
            <v-menu
                bottom
                right
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="close-menu"
                  @click="cancel()"
                >
                  <v-icon class="close-icon mt-6 mr-6 mt-md-0 mr-md-0">mdi-close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </div>
          <div v-if="!readOnly" class="container text-right">
            <v-menu
                bottom
                right
              >
            </v-menu>
          </div>
          <div class="container text-center mt-2">
            <img class="logo" :src="logoSvg" />
          </div>
          <h2 class="semibold-font mt-6 mb-6">{{ $t('terms') }}</h2>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="mt-6 mt-lg-2">
              <div style="word-break: break-word" v-html="termsContent"></div>

              <v-spacer></v-spacer>
              <v-col v-if="!readOnly" cols="12" lg="6" class="text-center mt-2">
                <button
                  class="main-button main-button-fixed"
                  @click="agreeTerms()"
                >{{ $t('agreeTerms') }}</button>
              </v-col>
              <v-col v-if="!readOnly" cols="12" lg="6" class="text-center grey-link mt-6">
                <a
                  @click="logout()"
                > {{ $t('disagreeTerms') }}
                </a>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-12 pt-12 text-center">
          <v-container>
            <v-divider class="hr-line"></v-divider>
            <div>
              <i class="fas fa-arrow-down icone-circle"></i>
            </div>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { AppStorage } from 'cuf-expo-bridge'

export default {
  name: 'termsModal',
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: true
    }
  },
  computed: {
    ...mapGetters(['currentClient']),
    ...mapGetters(['creatingDocumentState']),

    termsContent () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return this.etxRoTerms
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return this.etxBgTerms
      } else if (this.$i18n.locale === 'pl' && (this.currentClient && this.currentClient.agreement_with === 'CUF')) {
        return this.cufPlTerms
      } else {
        return this.etxPlTerms
      }
    },

    isScreenSmall () {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
    },

    logoSvg () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return require('@/assets/euro-tax.ro.svg')
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return require('@/assets/euro-tax.bg.svg')
      } else if (this.$i18n.locale === 'pl' || location.host.includes('.euro-tax.pl')) {
        if (this.$i18n.locale === 'ro') {
          return require('@/assets/euro-tax.ro.svg')
        } else if (this.$i18n.locale === 'bg') {
          return require('@/assets/euro-tax.bg.svg')
        } else if (this.currentClient && this.currentClient.agreement_with === 'CUF') {
          return require('@/assets/cuf-logo.svg')
        } else {
          return require('@/assets/euro-tax.pl.svg')
        }
      } else {
        return require('@/assets/cuf-logo.svg')
      }
    },

    cufPlTerms () {
      return `
        <p>Regulamin korzystania z Panelu Klienta obowiązujący od dnia 20.12.2024 r.</p>
        <p><strong> I. Definicje</strong></p>
        Użyte w Regulaminie pojęcia oznaczają:</br>
        <ol>
          <li>Klient – osoba, która założyła konto w Portalu Klienta, zaakceptowała Regulamin i jest stroną Umowy lub zamierza zawrzeć Umowę w przyszłości.</li>
          <li>Usługodawca - CUF sp. z o.o., firma z siedzibą pod adresem Szelągowska 25/2-3, 61-626 Poznań/Polska, zarejestrowana w rejestrze przedsiębiorców prowadzonym przez Sąd Rejonowy Poznań Nowe-Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy KRS. Kapitał zakładowy w wysokości 337 500,00 zł, opłacony w całości. Spółka należy do Grupy Kapitałowej Euro-Tax.pl S.A.</li>
          <li>Grupa Kapitałowa – spółki, w tym Usługodawca, należące do grupy kapitałowej Euro-Tax.pl S.A.</li>
          <li>Umowa – umowa zlecenie/ramowa zawarta ze spółką Grupy Kapitałowej lub zamówienie złożone spółce z Grupy Kapitałowej.</li>
          <li>Panel Klienta – oprogramowanie Usługodawcy umożliwiające kontakt i wymianę informacji, w tym składanie oświadczeń pomiędzy Klientem a Usługodawcą, a także zarządzanie usługami i zleceniami za pomocą urządzeń desktopowych i mobilnych, dostępne pod adresem strony internetowej: <a href="https://panel.cuf-podatki.pl/">https://panel.cuf-podatki.pl/</a> lub w formie Aplikacji Mobilnej.</li>
          <li>Aplikacja Mobilna – oprogramowanie przeznaczone do instalacji i działania na urządzeniach przenośnych z systemem Android lub iOS, takich jak smartfony i tablety, umożliwiające Klientowi korzystanie z Panelu Klienta za pośrednictwem tego oprogramowania. Aplikacja Mobilna dostępna jest do pobrania z oficjalnych sklepów z aplikacjami mobilnymi dedykowanymi dla danego systemu operacyjnego.</li>
          <li>Numer Klienta – ciąg cyfr zdefiniowany i wysłany przez Usługodawcę, dzięki któremu Klient będzie mógł logować się do Panelu Klienta.</li>
          <li>Tymczasowe hasło - ciąg znaków przesłanych w formie SMS na telefon komórkowy Klienta podany Usługodawcy. Umożliwia pierwsze logowanie (autoryzację) na Panelu Klienta i jest ważne 14 dni od otrzymania.</li>
          <li>Hasło – ciąg znaków ustalany samodzielnie przez Klienta podczas procesu Rejestracji oraz w trakcie użytkowania w dowolnym czasie.</li>
          <li>Rejestracja – jednorazowa czynność oznaczająca założenie konta w Panelu Klienta poprzez uzupełnienie przez Klienta danych niezbędnych do założenia konta oraz akceptację Regulaminu, a następnie potwierdzona poprzez aktywację konta za pomocą linka aktywacyjnego. Prezentacja spraw Klienta na Panelu Klienta jest automatyczna i nie wymaga dodatkowych działań Klienta.</li>
          <li>Regulamin – niniejszy Regulamin.</li>
        </ol>
        <br />
        <p><strong> II. Postanowienia ogólne</strong></p>
        <ol>
          <li>Regulamin określa zasady korzystania z Panelu Klienta.</li>
          <li>Panel Klienta jest dostępny wyłącznie dla Klientów. W zakresie nieuregulowanym w Regulaminie stosuje się przepisy Kodeksu cywilnego.</li>
          <li>W zakresie dostępu do Panelu Klienta, Usługodawca nie jest dostawcą usługi Internetu. Klient powinien we własnym zakresie uzyskać dostęp do stanowiska komputerowego lub urządzenia mobilnego z dostępem do połączenia internetowego.</li>
          <li>Usługodawca ma prawo powierzyć wykonywanie bieżącej obsługi Panelu Klienta podwykonawcy, bez informowania o tym Klienta.</li>
          <li>Usługodawca zastrzega sobie prawo do zawieszenia udostępniania Panelu Klienta w stosunku do wszystkich lub niektórych Klientów, w szczególności z uwagi na konieczność okresowych prac eksploatacyjnych czy zmian oprogramowania.</li>
          <li>Usługodawca zastrzega sobie prawo do całkowitego zaprzestania udostępniania Panelu Klienta bez podania przyczyny po uprzednim poinformowaniu Usługobiorców drogą mailową o podjęciu takiej decyzji co najmniej 7 dni wcześniej.</li>
          <li>Usługodawca zastrzega sobie prawo do zmiany niniejszego Regulaminu w dowolnym czasie. Zmiany Regulaminu obowiązują od momentu wskazanego w nowym Regulaminie.</li>
          <li>Usługodawca, jako podmiot należący do Grupy Kapitałowej świadczy usługi opisane w regulaminie, w szczególności w zakresie Panelu Klienta, w tym przy użyciu Aplikacji Mobilnej pod marką „Euro-Tax”.</li>
        </ol>
        <br />
        <p><strong> III. Zakres i warunki korzystania z Panelu Klienta</strong></p>
        <ol>
          <li>Dostęp do Panelu Klienta możliwy jest po założeniu konta w Panelu Klienta za pośrednictwem strony internetowej lub Aplikacji Mobilnej.</li>
          <li>Założenie konta w Panelu Klienta jest równoznaczne z akceptacją niniejszego regulaminu.</li>
          <li>Do prawidłowego działania Panelu Klienta zalecane jest korzystanie z aktualnych wersji przeglądarek. Panel Klienta nie działa na przeglądarce Internet Explorer.</li>
          <li>Usługodawca zastrzega sobie prawo do modyfikowania technicznego sposobu realizacji dostępu i korzystania z Panelu Klienta, stosownie do zakresu uprawnień, możliwości technicznych i zasad bezpieczeństwa.</li>
          <li>Usługodawca ma prawo zamieszczania w Panelu Klienta treści reklamowych, marketingowych, dotyczących usług oraz produktów Usługodawcy lub podmiotów trzecich, które będą wyświetlane Klientowi pod warunkiem wyrażenia przez niego stosownych zgód.</li>
          <li>Na adres e-mail Klienta podany Usługodawcy mogą być wysyłane informacje techniczne, systemowe i obsługowe dotyczące funkcjonowania Panelu Klienta.</li>
          <li>Usługobiorca w każdej chwili ma możliwość usunięcia konta. W tym celu należy wysłać wiadomość email na adres bok@cuf.com.pl</li>
          <li>Usunięcie konta nie jest równoznaczne z wypowiedzeniem zawartej Umowy.</li>
          <li>Usługodawca zastrzega sobie prawo do zablokowania dostępu do Panelu Klienta Klientowi naruszającemu postanowienia Regulaminu.</li>
          <li>Prawa wyłączne do treści udostępnianych w ramach Panelu Klienta, w szczególności prawa autorskie, nazwy i znaki towarowe, inne nazwy handlowe, oprogramowanie oraz prawa w zakresie baz danych podlegają ochronie prawnej.</li>
        </ol>
        <br />
        <p><strong> IV. Rejestracja</strong></p>
        <ol>
          <li>Rejestracji może dokonać każdy posiadający pełną zdolność do czynności prawnych.</li>
          <li>Rejestracja konta w Panelu Klienta jest darmowa.</li>
          <li>W celu Rejestracji w Panelu Klienta Klient zobowiązany jest podać:
            <ol type="a">
              <li>Imię i nazwisko;</li>
              <li>Numer telefonu;</li>
              <li>Adres e-mail;</li>
              <li>Datę urodzenia.</li>
            </ol>
          </li>
          <li>Aby dokonać Rejestracji należy:
            <ol type="a">
              <li>uzupełnić dane wymagane do rejestracji w Panelu Klienta;</li>
              <li>pobrać Regulamin oraz zapoznać się z jego treścią;</li>
              <li>zaakceptować treść Regulaminu;</li>
              <li>otworzyć przesłany w wiadomości e-mail link aktywacyjny;</li>
              <li>autoryzować rejestrację hasłem tymczasowym przesłanym w wiadomości SMS na numer telefonu podany Usługodawcy, w przypadku braku otrzymania hasła należy autoryzować chęć dostępu z pracownikiem Działu Obsługi Klienta;</li>
              <li>zdefiniować nowe hasło, za pomocą którego Klient będzie mógł logować się do Panelu.</li>
            </ol>
          </li>
          <li>Po potwierdzeniu prawidłowej Rejestracji Klient uzyskuje dostęp do Panelu Klienta.</li>
          <li>Klient, który zawarł wiele umów ze spółkami Grupy Kapitałowej uzyska po Rejestracji dostęp do danych dotyczących wszystkich umów.</li>
        </ol>
        <br />
        <p><strong> V. Dostęp po Rejestracji</strong></p>
        <ol>
          <li>Panel Klienta umożliwia Klientowi, po rejestracji i zalogowaniu dostęp do funkcji i informacji dotyczących usług świadczonych przez Usługodawcę, w szczególności:
            <ol type="a">
              <li>prezentacje prowadzonych spraw w zakładce Moje sprawy;</li>
              <li>prezentacje najważniejszych informacji w zakładce Wiadomości;</li>
              <li>prezentacje danych kontaktowych w zakładce Kontakt;</li>
              <li>zawieranie Umów;</li>
              <li>prezentacje Etapu rozliczenia, Historii sprawy, Szacowanych kwot zwrotu/niedopłaty, Średniego czasu oczekiwania, Dokumentów sprawy, możliwości edycji danych konta dla sprawy, w Szczegóły sprawy;</li>
              <li>wprowadzanie przez Klienta danych niezbędnych dla realizacji Umów;</li>
              <li>przekazywanie przez Klienta dokumentacji niezbędnej dla realizacji Umów;</li>
              <li>możliwości edycji danych teleadresowych, danych dotyczących rachunku bankowego, hasła do logowania w Profilu Klienta;</li>
              <li>wyświetlanie w Historii sprawy i Wiadomościach danych związanych z prowadzonymi sprawami; kwotami wypłat, kwotami zobowiązań, terminami wyznaczonymi przez zagraniczne i polskie urzędy i instytucje i inne;</li>
              <li>wyświetlanie w Dokumentach sprawy załączników typu umowa, oświadczenia dotyczące rachunków bankowych, faktury, dokumenty dostarczone przez Klienta za pośrednictwem Panelu Klienta i inne pisma związane z rozliczeniem, w tym korespondencje i decyzje z polskich i zagranicznych urzędów i instytucji.</li>
            </ol>
          </li>
          <li>Klient jest zobowiązany w szczególności do:
            <ol type="a">
              <li>niepodejmowania działań takich jak: udostępnianie swojego Konta osobom trzecim, podszywanie się pod osoby trzecie, przenoszenie na osoby trzecie prawa korzystania z Konta, podejmowanie czynności informatycznych lub wszelkich innych mających na celu wejście w posiadanie numerów klientów lub haseł innych klientów;</li>
              <li>utrzymywania w poufności danych potrzebnych do logowania do Panelu Klienta (w szczególności Hasła) oraz do dołożenia najwyższej staranności w celu uniemożliwienia osobom trzecim wejście w ich posiadanie;</li>
              <li>korzystania z Panelu Klienta w sposób zgodny z przepisami obowiązującego prawa, postanowieniami Regulaminu, a także z przyjętymi w danym zakresie zwyczajami;</li>
              <li>niezwłocznie powiadomić Usługodawcę o każdym przypadku naruszenia jego praw przez osoby trzecie, jak również o znanym mu przypadku naruszenia zasad określonych w Regulaminie przez osoby trzecie;</li>
            </ol>
          </li>
          <li>Klient nie może umieszczać w Panelu Klienta treści o charakterze bezprawnym.</li>
          <li>Usługodawca może pozbawić Klienta prawa do korzystania z Panelu Klienta lub ograniczyć widoczność prezentowanych spraw i załączników (blokując lub usuwając jego Konto) w przypadku naruszenia przez korzystającego Regulaminu, a w szczególności ust. 3 powyżej lub gdy Klient podał Usługodawcy dane niezgodne z prawdą, niedokładne lub nieaktualne, wprowadzające w błąd lub naruszające prawa osób trzecich i Usługodawcy, a także w sytuacji gdy przechowywanie danych do danej usługi jest prawnie nieuzasadnione po stronie Usługodawcy.</li>
          <li>Usługodawca prowadzi z Klientem komunikację dotyczącą korzystania z Panelu Klienta za pośrednictwem wiadomości SMS, e-mail, komunikatorów internetowych wykorzystywanych przez Usługodawcę, rozmowy telefonicznej z pracownikiem Działu Obsługi Klienta lub powiadomień PUSH.</li>
          <li>Konta nieużytkowane przez okres dłuższy niż 12 miesięcy od ostatniego zalogowania, mogą zostać przez Usługodawcę usunięte bez uprzedniego powiadomienia Klienta.</li>
          <li>Usługodawca zaleca Klientowi zmianę Hasła co 30 dni w celu zachowania bezpieczeństwa danych.</li>
          <li>Klient zobowiązany jest do:
            <ol type="a">
              <li>podawania danych zgodnych z prawdą, dokładnych i aktualnych, niewprowadzających w błąd oraz nienaruszających praw osób trzecich, w szczególności chodzi o dane teleadresowe oraz dane dotyczące numeru konta</li>
              <li>aktualizacji danych teleadresowych niezwłocznie, po każdej zmianie tych danych.</li>
            </ol>
          </li>
        </ol>
        <br />
        <p><strong> VI. Odpowiedzialność</strong></p>
        <ol>
          <li>Klient ponosi pełną i wyłączną odpowiedzialność za prawdziwość danych i dokumentów przekazanych Usługodawcy z wykorzystaniem Panelu Klienta.</li>
          <li>Usługodawca nie ponosi odpowiedzialności za problemy techniczne bądź ograniczenia techniczne występujące w sprzęcie komputerowym, urządzeniu końcowym, systemie teleinformatycznym oraz infrastrukturze telekomunikacyjnej, którymi posługuje się Klient, a które uniemożliwiają poprawne korzystanie z Panelu Klienta</li>
          <li>Klient ponosi odpowiedzialność za działania lub zaniechania innego podmiotu, któremu umożliwia korzystanie ze swojego Konta, tak jak za działania lub zaniechania własne.</li>
          <li>Jeżeli bezwzględnie obowiązujące przepisy prawa nie stanowią inaczej, Usługodawca nie ponosi odpowiedzialności za:
            <ol type="a">
              <li>negatywne konsekwencje braku wylogowania się przez Klienta z Panelu Klienta po zakończeniu sesji;</li>
              <li>skutki korzystania z Panelu Klienta przez Klienta w sposób sprzeczny z obowiązującym prawem, postanowieniami Regulaminu oraz związane z tym ewentualne szkody;</li>
              <li>skutki wykorzystania w trakcie procesu Rejestracji przez osobę nieuprawnioną adresu e-mail i/lub numeru telefonu osoby, która nie wyraziła na to zgody;</li>
              <li>zamieszczone przez Klienta dane, które stanowią treści bezprawne.</li>
            </ol>
          </li>
          <li>Jeżeli usługodawca otrzyma informację, że zamieszczone przez Klienta dane są treściami bezprawnymi Usługodawca niezwłocznie podejmie odpowiednie działania w celu usunięcia lub uniemożliwienia dostępu do bezprawnych treści.</li>
        </ol>
        <br />
        <p><strong> VII. Spory i Reklamacje</strong></p>
        <ol>
          <li>Wszelkie spory umowne będą rozpatrywane przez sądy powszechne. Klient ma prawo skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń, w szczególności poprzez zastosowanie przepisów alternatywnych metod rozstrzygania sporów (ADR) – z wykorzystaniem niezależnych instytucji pozasądowych – w szczególności rzeczników praw konsumenta, arbitrów, mediatorów. Więcej informacji Zleceniodawca może znaleźć na stronie UOKiK: <a href="http://www.uokik.gov.pl/spory_konsumenckie.php">http://www.uokik.gov.pl/spory_konsumenckie.php</a>.</li>
          <li>W sprawach nieuregulowanych niniejszym Regulaminem zastosowanie mają odpowiednie przepisy Kodeksu Cywilnego.</li>
          <li>Klient może zgłosić Usługodawcy wszelkie nieprawidłowości związane z funkcjonowaniem Panelu Klienta, w szczególności pisemnie lub za pomocą poczty elektronicznej kierując maila na adres <a href="mailto:bok@cuf.com.pl">bok@cuf.com.pl</a>. Reklamacje rozpatrywane są w ciągu 30 dni roboczych od otrzymania wiadomości email.</li>
        </ol>
        <br />
        <p><strong> VIII. Dane osobowe i cookies</strong></p>
        <ol>
          <li>Administratorem danych osobowych jest Usługodawca, przy czym dane te mogą być udostępniane także podmiotom powiązanym kapitałowo z Usługodawcą.</li>
          <li>Celem przetwarzania danych na Panelu Klienta jest ich prezentacja Klientowi, co związane jest z realizacją zleconej przez Klienta usługi. Więcej informacji na ten temat znajdziesz tu; <a href="https://cuf-podatki.pl/polityka-prywatnosci/">https://cuf-podatki.pl/polityka-prywatnosci/</a></li>
          <li>Na stronach Panelu Klienta stosowane są pliki cookies. Korzystanie z Panelu Klienta bez zmiany ustawień przeglądarki oznacza, że pliki cookies będą zamieszczane w urządzeniu Klienta. Więcej informacji na ten temat znajdziesz tu; <a href="https://cuf-podatki.pl/polityka-prywatnosci/">https://cuf-podatki.pl/polityka-prywatnosci/</a></li>
          <li>Każdy Zleceniodawca może wyłączyć mechanizm „cookies” w przeglądarce internetowej swojego urządzenia końcowego. Zleceniobiorca wskazuje, że wyłączenie „cookies” może jednak spowodować utrudnienia lub uniemożliwić korzystanie z usługi towarzyszącej przez danego Zleceniodawcę.</li>
        </ol>
        <br />
        <p><strong> IX. Prawa Autorskie</strong></p>
        <ol>
          <li>Klient poprzez korzystanie z Aplikacji Mobilnej lub Panelu Klienta nie nabywa autorskich praw majątkowych do Aplikacji Mobilnej lub Panelu Klienta.</li>
          <li>Klient nie ma prawa do:
            <ol type="a">
              <li>modyfikowania, zmieniania, ulepszania lub poprawiania Aplikacji Mobilnej lub Panelu Klienta;</li>
              <li>rozpowszechniania poza Aplikacją Mobilną i Panelem Klienta znajdujących się w nich treści;</li>
              <li>odpłatnego dystrybuowania lub sublicencjonowania Aplikacji Mobilnej lub Panelu Klienta.</li>
            </ol>
          </li>
        </ol>
      `
    },

    etxPlTerms () {
      return `
        <p>Regulamin korzystania z Panelu Klienta obowiązujący od dnia 20.12.2024 r.</p>
        <p><strong> I. Definicje</strong></p>
        Użyte w Regulaminie pojęcia oznaczają:</br>
        <ol>
          <li>Klient – osoba, która założyła konto w Portalu Klienta, zaakceptowała Regulamin i jest stroną Umowy lub zamierza zawrzeć Umowę w przyszłości.</li>
          <li>Usługodawca - Euro-Tax.pl Zwrot Podatku S.A., firma z siedzibą pod adresem ul. W. Sikorskiego 26 53-659 Wrocław, Polska, zarejestrowana w Sądzie Rejonowym dla Wrocławia Fabrycznej VI Wydział Gospodarczy KRS, KRS: 00000480703, Kapitał zakładowy: 100 000,00 zł, opłacony w całości. Spółka należy do Grupy Kapitałowej Euro-Tax.pl S.A.</li>
          <li>Grupa Kapitałowa – spółki, w tym Usługodawca, należące do grupy kapitałowej Euro-Tax.pl S.A.</li>
          <li>Umowa – umowa zlecenie/ramowa zawarta ze spółką Grupy Kapitałowej lub zamówienie złożone spółce z Grupy Kapitałowej.</li>
          <li>Panel Klienta – oprogramowanie Usługodawcy umożliwiające kontakt i wymianę informacji, w tym składanie oświadczeń pomiędzy Klientem a Usługodawcą, a także zarządzanie usługami i zleceniami za pomocą urządzeń desktopowych i mobilnych, dostępne pod adresem strony internetowej: <a href="https://panel.euro-tax.pl">https://panel.euro-tax.pl</a> lub w formie Aplikacji Mobilnej.</li>
          <li>Aplikacja Mobilna – oprogramowanie przeznaczone do instalacji i działania na urządzeniach przenośnych z systemem Android lub iOS, takich jak smartfony i tablety, umożliwiające Klientowi korzystanie z Panelu Klienta za pośrednictwem tego oprogramowania. Aplikacja Mobilna dostępna jest do pobrania z oficjalnych sklepów z aplikacjami mobilnymi dedykowanymi dla danego systemu operacyjnego.</li>
          <li>Numer Klienta – ciąg cyfr zdefiniowany i wysłany przez Usługodawcę, dzięki któremu Klient będzie mógł logować się do Panelu Klienta.</li>
          <li>Tymczasowe hasło - ciąg znaków przesłanych w formie SMS na telefon komórkowy Klienta podany Usługodawcy. Umożliwia pierwsze logowanie (autoryzację) na Panelu Klienta i jest ważne 14 dni od otrzymania.</li>
          <li>Hasło – ciąg znaków ustalany samodzielnie przez Klienta podczas procesu Rejestracji oraz w trakcie użytkowania w dowolnym czasie.</li>
          <li>Rejestracja – jednorazowa czynność oznaczająca założenie konta w Panelu Klienta poprzez uzupełnienie przez Klienta danych niezbędnych do założenia konta oraz akceptację Regulaminu, a następnie potwierdzona poprzez aktywację konta za pomocą linka aktywacyjnego. Prezentacja spraw Klienta na Panelu Klienta jest automatyczna i nie wymaga dodatkowych działań Klienta.</li>
          <li>Regulamin – niniejszy Regulamin.</li>
        </ol>
        <br />
        <p><strong> II. Postanowienia ogólne</strong></p>
        <ol>
          <li>Regulamin określa zasady korzystania z Panelu Klienta.</li>
          <li>Panel Klienta jest dostępny wyłącznie dla Klientów. W zakresie nieuregulowanym w Regulaminie stosuje się przepisy Kodeksu cywilnego.</li>
          <li>W zakresie dostępu do Panelu Klienta, Usługodawca nie jest dostawcą usługi Internetu. Klient powinien we własnym zakresie uzyskać dostęp do stanowiska komputerowego lub urządzenia mobilnego z dostępem do połączenia internetowego.</li>
          <li>Usługodawca ma prawo powierzyć wykonywanie bieżącej obsługi Panelu Klienta podwykonawcy, bez informowania o tym Klienta.</li>
          <li>Usługodawca zastrzega sobie prawo do zawieszenia udostępniania Panelu Klienta w stosunku do wszystkich lub niektórych Klientów, w szczególności z uwagi na konieczność okresowych prac eksploatacyjnych czy zmian oprogramowania.</li>
          <li>Usługodawca zastrzega sobie prawo do całkowitego zaprzestania udostępniania Panelu Klienta bez podania przyczyny po uprzednim poinformowaniu Usługobiorców drogą mailową o podjęciu takiej decyzji co najmniej 7 dni wcześniej.</li>
          <li>Usługodawca zastrzega sobie prawo do zmiany niniejszego Regulaminu w dowolnym czasie. Zmiany Regulaminu obowiązują od momentu wskazanego w nowym Regulaminie.</li>
          <li>Usługodawca, jako podmiot należący do Grupy Kapitałowej świadczy usługi opisane w regulaminie, w szczególności w zakresie Panelu Klienta, w tym przy użyciu Aplikacji Mobilnej pod marką „Euro-Tax”.</li>
        </ol>
        <br />
        <p><strong> III. Zakres i warunki korzystania z Panelu Klienta</strong></p>
        <ol>
          <li>Dostęp do Panelu Klienta możliwy jest po założeniu konta w Panelu Klienta za pośrednictwem strony internetowej lub Aplikacji Mobilnej.</li>
          <li>Założenie konta w Panelu Klienta jest równoznaczne z akceptacją niniejszego regulaminu.</li>
          <li>Do prawidłowego działania Panelu Klienta zalecane jest korzystanie z aktualnych wersji przeglądarek. Panel Klienta nie działa na przeglądarce Internet Explorer.</li>
          <li>Usługodawca zastrzega sobie prawo do modyfikowania technicznego sposobu realizacji dostępu i korzystania z Panelu Klienta, stosownie do zakresu uprawnień, możliwości technicznych i zasad bezpieczeństwa.</li>
          <li>Usługodawca ma prawo zamieszczania w Panelu Klienta treści reklamowych, marketingowych, dotyczących usług oraz produktów Usługodawcy lub podmiotów trzecich, które będą wyświetlane Klientowi pod warunkiem wyrażenia przez niego stosownych zgód.</li>
          <li>Na adres e-mail Klienta podany Usługodawcy mogą być wysyłane informacje techniczne, systemowe i obsługowe dotyczące funkcjonowania Panelu Klienta.</li>
          <li>Usługobiorca w każdej chwili ma możliwość usunięcia konta. W tym celu należy wysłać wiadomość email na adres bok@euro-tax.pl</li>
          <li>Usunięcie konta nie jest równoznaczne z wypowiedzeniem zawartej Umowy.</li>
          <li>Usługodawca zastrzega sobie prawo do zablokowania dostępu do Panelu Klienta Klientowi naruszającemu postanowienia Regulaminu.</li>
          <li>Prawa wyłączne do treści udostępnianych w ramach Panelu Klienta, w szczególności prawa autorskie, nazwy i znaki towarowe, inne nazwy handlowe, oprogramowanie oraz prawa w zakresie baz danych podlegają ochronie prawnej.</li>
        </ol>
        <br />
        <p><strong> IV. Rejestracja</strong></p>
        <ol>
          <li>Rejestracji może dokonać każdy posiadający pełną zdolność do czynności prawnych.</li>
          <li>Rejestracja konta w Panelu Klienta jest darmowa.</li>
          <li>W celu Rejestracji w Panelu Klienta Klient zobowiązany jest podać:
            <ol type="a">
              <li>Imię i nazwisko;</li>
              <li>Numer telefonu;</li>
              <li>Adres e-mail;</li>
              <li>Datę urodzenia.</li>
            </ol>
          </li>
          <li>Aby dokonać Rejestracji należy:
            <ol type="a">
              <li>uzupełnić dane wymagane do rejestracji w Panelu Klienta;</li>
              <li>pobrać Regulamin oraz zapoznać się z jego treścią;</li>
              <li>zaakceptować treść Regulaminu;</li>
              <li>otworzyć przesłany w wiadomości e-mail link aktywacyjny;</li>
              <li>autoryzować rejestrację hasłem tymczasowym przesłanym w wiadomości SMS na numer telefonu podany Usługodawcy, w przypadku braku otrzymania hasła należy autoryzować chęć dostępu z pracownikiem Działu Obsługi Klienta;</li>
              <li>zdefiniować nowe hasło, za pomocą którego Klient będzie mógł logować się do Panelu.</li>
            </ol>
          </li>
          <li>Po potwierdzeniu prawidłowej Rejestracji Klient uzyskuje dostęp do Panelu Klienta.</li>
          <li>Klient, który zawarł wiele umów ze spółkami Grupy Kapitałowej uzyska po Rejestracji dostęp do danych dotyczących wszystkich umów.</li>
        </ol>
        <br />
        <p><strong> V. Dostęp po Rejestracji</strong></p>
        <ol>
          <li>Panel Klienta umożliwia Klientowi, po rejestracji i zalogowaniu dostęp do funkcji i informacji dotyczących usług świadczonych przez Usługodawcę, w szczególności:
            <ol type="a">
              <li>prezentacje prowadzonych spraw w zakładce Moje sprawy;</li>
              <li>prezentacje najważniejszych informacji w zakładce Wiadomości;</li>
              <li>prezentacje danych kontaktowych w zakładce Kontakt;</li>
              <li>zawieranie Umów;</li>
              <li>prezentacje Etapu rozliczenia, Historii sprawy, Szacowanych kwot zwrotu/niedopłaty, Średniego czasu oczekiwania, Dokumentów sprawy, możliwości edycji danych konta dla sprawy, w Szczegóły sprawy;</li>
              <li>wprowadzanie przez Klienta danych niezbędnych dla realizacji Umów;</li>
              <li>przekazywanie przez Klienta dokumentacji niezbędnej dla realizacji Umów;</li>
              <li>możliwości edycji danych teleadresowych, danych dotyczących rachunku bankowego, hasła do logowania w Profilu Klienta;</li>
              <li>wyświetlanie w Historii sprawy i Wiadomościach danych związanych z prowadzonymi sprawami; kwotami wypłat, kwotami zobowiązań, terminami wyznaczonymi przez zagraniczne i polskie urzędy i instytucje i inne;</li>
              <li>wyświetlanie w Dokumentach sprawy załączników typu umowa, oświadczenia dotyczące rachunków bankowych, faktury, dokumenty dostarczone przez Klienta za pośrednictwem Panelu Klienta i inne pisma związane z rozliczeniem, w tym korespondencje i decyzje z polskich i zagranicznych urzędów i instytucji.</li>
            </ol>
          </li>
          <li>Klient jest zobowiązany w szczególności do:
            <ol type="a">
              <li>niepodejmowania działań takich jak: udostępnianie swojego Konta osobom trzecim, podszywanie się pod osoby trzecie, przenoszenie na osoby trzecie prawa korzystania z Konta, podejmowanie czynności informatycznych lub wszelkich innych mających na celu wejście w posiadanie numerów klientów lub haseł innych klientów;</li>
              <li>utrzymywania w poufności danych potrzebnych do logowania do Panelu Klienta (w szczególności Hasła) oraz do dołożenia najwyższej staranności w celu uniemożliwienia osobom trzecim wejście w ich posiadanie;</li>
              <li>korzystania z Panelu Klienta w sposób zgodny z przepisami obowiązującego prawa, postanowieniami Regulaminu, a także z przyjętymi w danym zakresie zwyczajami;</li>
              <li>niezwłocznie powiadomić Usługodawcę o każdym przypadku naruszenia jego praw przez osoby trzecie, jak również o znanym mu przypadku naruszenia zasad określonych w Regulaminie przez osoby trzecie;</li>
            </ol>
          </li>
          <li>Klient nie może umieszczać w Panelu Klienta treści o charakterze bezprawnym.</li>
          <li>Usługodawca może pozbawić Klienta prawa do korzystania z Panelu Klienta lub ograniczyć widoczność prezentowanych spraw i załączników (blokując lub usuwając jego Konto) w przypadku naruszenia przez korzystającego Regulaminu, a w szczególności ust. 3 powyżej lub gdy Klient podał Usługodawcy dane niezgodne z prawdą, niedokładne lub nieaktualne, wprowadzające w błąd lub naruszające prawa osób trzecich i Usługodawcy, a także w sytuacji gdy przechowywanie danych do danej usługi jest prawnie nieuzasadnione po stronie Usługodawcy.</li>
          <li>Usługodawca prowadzi z Klientem komunikację dotyczącą korzystania z Panelu Klienta za pośrednictwem wiadomości SMS, e-mail, komunikatorów internetowych wykorzystywanych przez Usługodawcę, rozmowy telefonicznej z pracownikiem Działu Obsługi Klienta lub powiadomień PUSH.</li>
          <li>Konta nieużytkowane przez okres dłuższy niż 12 miesięcy od ostatniego zalogowania, mogą zostać przez Usługodawcę usunięte bez uprzedniego powiadomienia Klienta.</li>
          <li>Usługodawca zaleca Klientowi zmianę Hasła co 30 dni w celu zachowania bezpieczeństwa danych.</li>
          <li>Klient zobowiązany jest do:
            <ol type="a">
              <li>podawania danych zgodnych z prawdą, dokładnych i aktualnych, niewprowadzających w błąd oraz nienaruszających praw osób trzecich, w szczególności chodzi o dane teleadresowe oraz dane dotyczące numeru konta</li>
              <li>aktualizacji danych teleadresowych niezwłocznie, po każdej zmianie tych danych.</li>
            </ol>
          </li>
        </ol>
        <br />
        <p><strong> VI. Odpowiedzialność</strong></p>
        <ol>
          <li>Klient ponosi pełną i wyłączną odpowiedzialność za prawdziwość danych i dokumentów przekazanych Usługodawcy z wykorzystaniem Panelu Klienta.</li>
          <li>Usługodawca nie ponosi odpowiedzialności za problemy techniczne bądź ograniczenia techniczne występujące w sprzęcie komputerowym, urządzeniu końcowym, systemie teleinformatycznym oraz infrastrukturze telekomunikacyjnej, którymi posługuje się Klient, a które uniemożliwiają poprawne korzystanie z Panelu Klienta</li>
          <li>Klient ponosi odpowiedzialność za działania lub zaniechania innego podmiotu, któremu umożliwia korzystanie ze swojego Konta, tak jak za działania lub zaniechania własne.</li>
          <li>Jeżeli bezwzględnie obowiązujące przepisy prawa nie stanowią inaczej, Usługodawca nie ponosi odpowiedzialności za:
            <ol type="a">
              <li>negatywne konsekwencje braku wylogowania się przez Klienta z Panelu Klienta po zakończeniu sesji;</li>
              <li>skutki korzystania z Panelu Klienta przez Klienta w sposób sprzeczny z obowiązującym prawem, postanowieniami Regulaminu oraz związane z tym ewentualne szkody;</li>
              <li>skutki wykorzystania w trakcie procesu Rejestracji przez osobę nieuprawnioną adresu e-mail i/lub numeru telefonu osoby, która nie wyraziła na to zgody;</li>
              <li>zamieszczone przez Klienta dane, które stanowią treści bezprawne.</li>
            </ol>
          </li>
          <li>Jeżeli usługodawca otrzyma informację, że zamieszczone przez Klienta dane są treściami bezprawnymi Usługodawca niezwłocznie podejmie odpowiednie działania w celu usunięcia lub uniemożliwienia dostępu do bezprawnych treści.</li>
        </ol>
        <br />
        <p><strong> VII. Spory i Reklamacje</strong></p>
        <ol>
          <li>Wszelkie spory umowne będą rozpatrywane przez sądy powszechne. Klient ma prawo skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń, w szczególności poprzez zastosowanie przepisów alternatywnych metod rozstrzygania sporów (ADR) – z wykorzystaniem niezależnych instytucji pozasądowych – w szczególności rzeczników praw konsumenta, arbitrów, mediatorów. Więcej informacji Zleceniodawca może znaleźć na stronie UOKiK: <a href="http://www.uokik.gov.pl/spory_konsumenckie.php">http://www.uokik.gov.pl/spory_konsumenckie.php</a>.</li>
          <li>W sprawach nieuregulowanych niniejszym Regulaminem zastosowanie mają odpowiednie przepisy Kodeksu Cywilnego.</li>
          <li>Klient może zgłosić Usługodawcy wszelkie nieprawidłowości związane z funkcjonowaniem Panelu Klienta, w szczególności pisemnie lub za pomocą poczty elektronicznej kierując maila na adres <a href="mailto:bok@euro-tax.pl">bok@euro-tax.pl</a>. Reklamacje rozpatrywane są w ciągu 30 dni roboczych od otrzymania wiadomości email.</li>
        </ol>
        <br />
        <p><strong> VIII. Dane osobowe i cookies</strong></p>
        <ol>
          <li>Administratorem danych osobowych jest Usługodawca, przy czym dane te mogą być udostępniane także podmiotom powiązanym kapitałowo z Usługodawcą.</li>
          <li>Celem przetwarzania danych na Panelu Klienta jest ich prezentacja Klientowi, co związane jest z realizacją zleconej przez Klienta usługi. Więcej informacji na ten temat znajdziesz tu; <a href="https://euro-tax.pl/polityka-prywatnosci/">https://euro-tax.pl/polityka-prywatnosci/</a></li>
          <li>Na stronach Panelu Klienta stosowane są pliki cookies. Korzystanie z Panelu Klienta bez zmiany ustawień przeglądarki oznacza, że pliki cookies będą zamieszczane w urządzeniu Klienta. Więcej informacji na ten temat znajdziesz tu; <a href="https://euro-tax.pl/polityka-prywatnosci/">https://euro-tax.pl/polityka-prywatnosci/</a></li>
          <li>Każdy Zleceniodawca może wyłączyć mechanizm „cookies” w przeglądarce internetowej swojego urządzenia końcowego. Zleceniobiorca wskazuje, że wyłączenie „cookies” może jednak spowodować utrudnienia lub uniemożliwić korzystanie z usługi towarzyszącej przez danego Zleceniodawcę.</li>
        </ol>
        <br />
        <p><strong> IX. Prawa Autorskie</strong></p>
        <ol>
          <li>Klient poprzez korzystanie z Aplikacji Mobilnej lub Panelu Klienta nie nabywa autorskich praw majątkowych do Aplikacji Mobilnej lub Panelu Klienta.</li>
          <li>Klient nie ma prawa do:
            <ol type="a">
              <li>modyfikowania, zmieniania, ulepszania lub poprawiania Aplikacji Mobilnej lub Panelu Klienta;</li>
              <li>rozpowszechniania poza Aplikacją Mobilną i Panelem Klienta znajdujących się w nich treści;</li>
              <li>odpłatnego dystrybuowania lub sublicencjonowania Aplikacji Mobilnej lub Panelu Klienta.</li>
            </ol>
          </li>
        </ol>
      `
    },

    etxBgTerms () {
      return `
        <p>Правила за използване на Клиентския Панел, валидни от 20.12.2024</p>
        <p><strong> I. Определения</strong></p>
        Термините, използвани в Правилата, означават:</br>
        <ol>
          <li>Клиент – Лице, което е създало потребителски профил в Клиентския Панел, приело е Общите Условия и е страна по Договора или възнамерява да встъпи в Договор в бъдеще.</li>
          <li>Доставчик на Услуги – „ЕВРО-ТАКС.БГ" ООД, със седалище и адрес на управление:  гр. София 1797, ж.к. Младост 1 бул. „Климент Охридски" 1А – етаж 3". Компанията е част от капиталовата група Euro-Tax.pl S.A. Capital Group.</li>
          <li>Капиталова Група – Компании (дружества), включително Доставчика на Услуги, които са част от Euro-Tax.pl S.A. Capital Group.</li>
          <li>Договор – Договор за услуги/рамков договор с компания (дружество) от Капиталовата Група или поръчка, направена към компания (дружество) от Капиталовата Група.</li>
          <li>Клиентски Панел– Софтуер на Доставчика на Услуги, който позволява контакт и обмен на информация, включително подаване на декларации между Клиента и Доставчика на Услуги, както и управление на услуги и поръчки чрез настолни и мобилни устройства, достъпен на: https://panel.euro-tax.bg или чрез Мобилно Приложение.</li>
          <li>Мобилно приложение – Софтуер, предназначен за инсталиране и работа на мобилни устройства с операционни системи Android или iOS, като смартфони и таблети, който позволява на Клиента достъп до Клиентския Панел чрез този софтуер. Мобилното Приложение може да бъде изтеглено от официалните магазини за приложения, съответстващи на операционната система.</li>
          <li>Клиентски номер – Последователност от цифри, определени и изпратени от Доставчика на Услуги, която позволява на Клиента да влезе в Клиентския Панел.</li>
          <li>Временна парола – Последователност от символи, изпратени чрез SMS до мобилния телефонен номер, предоставен от Клиента. Същата позволява първоначално влизане (автентикация) в Клиентския Панел и е валидна 14 дни от получаването.</li>
          <li>Парола – Последователност от символи, зададени самостоятелно от Клиента по време на процеса на Регистрация или по всяко време на употреба.</li>
          <li>Регистрация – Еднократна процедура за създаване на потребителски профил в Клиентския Панел чрез попълване на необходимите данни и приемане на Общите Условия, последвана от активиране на потребителския профил чрез активиращ линк. Случаите на Клиента се добавят в Клиентския Панел автоматично, без да са необходими допълнителни действия.</li>
          <li>Общи условия – Настоящите Общи Условия.</li>
        </ol>
        <br />
        <p><strong> II. Общи разпоредби</strong></p>
        <ol>
          <li>Настоящите Общи Условия определят правилата за използване на Клиентския Панел.</li>
          <li>Клиентският Панел е достъпен изключително за Клиенти. За случаи, които не попадат под разпоредбите на тези правил или за неизяснени в тях въпроси, се прилагат релевантните разпоредби на българското гражданско законодателство.</li>
          <li>Доставчикът на услуги не е интернет доставчик. Клиентът трябва самостоятелно да си осигури достъп до компютър или мобилно устройство с интернет връзка.</li>
          <li>Доставчикът на Услуги си запазва правото да възложи управлението на Клиентския Панел на подизпълнител, без да информира Клиента.</li>
          <li>Доставчикът на Услуги може да спре достъпа до Клиентския Панел за всички или някои Клиенти, особено за периодична поддръжка или актуализация на софтуера.</li>
          <li>Доставчикът на услуги си запазва правото да преустанови действието на Клиентския панел без да е необходимо да сочи основание за това, при условие че Клиентите бъдат уведомени по имейл най-малко 7 дни предварително.</li>
          <li>Доставчикът на Услуги може да променя тези Общи Условия по всяко време. Промените влизат в сила на датата, посочена в новите Общи Условия.</li>
          <li>Доставчикът на услуги, като част от Капиталовата Група, предоставя услуги, описани в тези Общи Условия, включително чрез Мобилното Приложение, под търговското наименование "Euro-Tax".</li>
        </ol>
        <br />
        <p><strong> III. Обхват и условия за използване на Клиентския Панел</strong></p>
        <ol>
          <li>Достъпът до Клиентския панел е възможен след създаване на потребителски профил чрез уебсайта или Мобилното Приложение.</li>
          <li>Създаването на потребителски профил в Клиентския Панел означава приемане на тези Общи Условия.</li>
          <li>За оптимална функционалност се препоръчва използването на последни версии на браузъри. Клиентският Панел не поддържа Internet Explorer.</li>
          <li>Доставчикът на Услуги може да променя техническите методи за предоставяне на достъп и използване на Клиентския Панел в съответствие с наличните технически възможности и мерки за сигурност.</li>
          <li>Доставчикът на Услуги може да показва реклами и маркетингово съдържание в Клиентския Панел, при условие че Клиентът е дал съответното съгласие.</li>
          <li>Техническа, системна и свързана с услугите информация за Клиентския Панел може да бъде изпращана на имейл адреса, предоставен от Клиента на Доставчика на Услуги.</li>
          <li>Клиентите могат да изтрият потребителския си профил по всяко време, като изпратят имейл до dok@euro-tax.bg.</li>
          <li>Изтриването на потребителския профил не прекратява вече сключени Договори.</li>
          <li>Доставчикът на Услуги си запазва правото да блокира достъпа до Клиентския Панел за Клиенти, които нарушават Общите Условия.</li>
          <li>Правата върху съдържанието, споделено в Клиентския Панел, включително авторски права, търговски марки, търговски наименования, софтуер и права върху бази данни, са законово защитени.</li>
        </ol>
        <br />
        <p><strong> IV. Регистрация</strong></p>
        <ol>
          <li>Регистрацията е достъпна за всяко дееспособно лице.</li>
          <li>Регистрацията в Клиентския Панел е безплатна.</li>
          <li>За да се регистрира в Клиентския Панел, Клиентът трябва да предостави:
            <ol type="a">
              <li>Пълни имена,</li>
              <li>Телефонен номер,</li>
              <li>Имейл адрес,</li>
              <li>Дата на раждане.</li>
            </ol>
          </li>
          <li>За да завърши процеса на Регистрация, Клиентът трябва:
            <ol type="a">
              <li>Да предостави изискваните регистрационни данни,</li>
              <li>Да изтегли и прегледа тези Общи Условия,</li>
              <li>Да приеме Общите Условия,</li>
              <li>Да активира клиентския панел чрез линка за активиране, изпратен по имейл,</li>
              <li>Да потвърди регистрацията с временна парола, изпратена чрез SMS. Ако временната парола не бъде получена, потвърждението може да се извърши с помощта на отдел „Обслужване на Клиенти" ,</li>
              <li>Да зададе нова парола за достъп до Клиентския Панел.</li>
            </ol>
          </li>
          <li>След успешно завършване на Регистрацията, Клиентът получава достъп до Клиентския Панел.</li>
          <li>Клиент, който е сключил множество договори с компании (дружества) от Капиталовата Група, ще има достъп до информация за всички договори след Регистрация.</li>
        </ol>
        <br />
        <p><strong> V. Достъп след регистрация</strong></p>
        <ol>
          <li>След регистрация и вход в системата, Клиентският Панел предоставя достъп до различни функции и информация относно услугите, предоставяни от Доставчика на Услуги, включително:
            <ol type="a">
              <li>Преглед на случаите в раздел „Моите случаи" ,</li>
              <li>Показване на ключова информация в раздел „Съобщения" ,</li>
              <li>Показване на данни за контакт в раздел „Договори" ,</li>
              <li>Сключване на договори,</li>
              <li>Представяне на детайлна информация като Етап на Обработка, История на Случая, Приблизителна Сума за Възстановяване/Дължима Сума, Средно Време за Обработка, Документи по Случая и опции за редактиране на данни в раздел „Детайли на случая" ,</li>
              <li>Въвеждане на данни, необходими за изпълнение на договора,</li>
              <li>Подаване на документи, изисквани за изпълнение на Договора,</li>
              <li>Редактиране на данни за контакт, информация за банкова сметка и пароли за достъп в раздел „Потребителски профил" ,</li>
              <li>Преглед на исторически данни и съобщения, свързани със случаи, суми на плащания, задължения и срокове от Български и чуждестранни институции,</li>
              <li>Достъп до документи, свързани със случаите, като договори, банкови извлечения, фактури и кореспонденция с институции.</li>
            </ol>
          </li>
          <li>Клиентът е длъжен:
            <ol type="a">
              <li>Да не извършва действия като споделяне на потребителския си профил с трети лица, представяне за други лица, прехвърляне на права върху потребителския профил на трети лица или опити за неоторизиран достъп до потребителски профили или пароли на други Клиенти,</li>
              <li>Да съхранява данните за достъп (особено Паролата) поверително и да предприема всички необходими мерки за предотвратяване на неоторизиран достъп,</li>
              <li>Да използва Клиентския Панел в съответствие с приложимото законодателство, тези Общи Условия и утвърдените практики,</li>
              <li>Незабавно да уведоми Доставчика на Услуги за всяко нарушение на неговите права или информация за нарушения на Общите Условия.</li>
            </ol>
          </li>
          <li>Клиентът няма право да качва незаконно съдържание в Клиентския Панел.</li>
          <li>Доставчикът на Услуги може да отнеме достъпа до Клиентския Панел или да ограничи видимостта на случаи и прикачени файлове (чрез блокиране или изтриване на потребителския профил), ако Клиентът наруши Общите Условия, предостави неверни, неточни или остарели данни или в случаи, когато съхранението на тези данни вече не е законово обосновано.</li>
          <li>Доставчикът на услуги комуникира с Клиента относно Клиентския Панел чрез SMS, имейл, онлайн платформи за съобщения, телефонни обаждания с отдел „Обслужване на Клиенти" или чрез push нотификации.</li>
          <li>Потребителски профили, които останат неактивни повече от 12 месеца от последния вход, могат да бъдат изтрити от Доставчика на Услуги без предварително уведомление.</li>
          <li>За осигуряване на сигурността на данните, Доставчикът на Услуги препоръчва промяна на паролите на всеки 30 дни.</li>
          <li>Клиентът се задължава:
            <ol type="a">
              <li>Да предоставя точна, актуална и вярна информация, особено данни за контакт и данни за банкова сметка,</li>
              <li>Да актуализира данните си за контакт незабавно при настъпване на промени.</li>
            </ol>
          </li>
        </ol>
        <br />
        <p><strong> VI. Отговорност</strong></p>
        <ol>
          <li>Клиентът носи пълна отговорност за точността на данните и документите, предоставени на Доставчика на Услуги чрез Клиентския Панел.</li>
          <li>Доставчикът на Услуги не носи отговорност за технически проблеми или ограничения в оборудването, системите или телекомуникационната инфраструктура на Клиента, които възпрепятстват правилното използване на Клиентския Панел.</li>
          <li>Клиентът носи отговорност за действията или бездействията на трети лица, на които е предоставил достъп до своя потребителски профил, сякаш тези действия са извършени от самия него.</li>
          <li>Освен ако задължителните правни разпоредби не предвиждат друго, Доставчикът на услуги не носи отговорност за:
            <ol type="a">
              <li>Негативни последици от това, че Клиентът не е излязъл от системата след използване на Клиентския Панел,</li>
              <li>Последици от използването на Клиентския Панел в нарушение на закона или на тези Общи Условия,</li>
              <li>Неоторизирано използване на имейл адреси или телефонни номера по време на Регистрация,</li>
              <li>Съдържание, качено от Клиента, което а незаконно, неистинско или невярно.</li>
            </ol>
          </li>
          <li>При надлежно уведомление, че каченото съдържание е незаконно, неистинско или невярно, Доставчикът на Услуги ще предприеме подходящи мерки за премахване или блокиране на достъпа до това съдържание.</li>
        </ol>
        <br />
        <p><strong> VII. Спореве и Жалби</strong></p>
        <ol>
          <li>Всички договорни спорове ще бъдат решавани от компетентния съд. Клиентите имат право да използват алтернативни механизми за разрешаване на спор.</li>
          <li>Въпросите, които не са засегнати в тези Общи Условия, се регулират от разпоредбите на българското гражданско законодателство.</li>
          <li>Клиентът може да докладва за нередности, свързани с функционирането на Клиентския панел, в писмен вид или по имейл на адрес dok@euro-tax.bg. Оплакванията ще бъдат разгледани в рамките на 30 работни дни от получаването им.</li>
        </ol>
        <br />
        <p><strong> VIII. Лични данни и бисквитки</strong></p>
        <ol>
          <li>Доставчикът на Услуги е администратор на лични данни, които могат да бъдат споделяни и с компании (дружества), свързани с Доставчика на Услуги.</li>
          <li>Целта на обработката на данни в Клиентския Панел е да бъдат представени на Клиента във връзка с изпълнението на услугата. Повече информация може да бъде намерена на: <a href="https://euro-tax.bg/politika-za-poveritelnost">https://euro-tax.bg/politika-za-poveritelnost</a>.</li>
          <li>На страниците на Клиентския панел се използват бисквитки. Използването на Клиентския Панел без промяна на настройките на браузъра означава, че бисквитките ще бъдат запазени на устройството на Клиента. Повече информация: <a href="https://euro-tax.bg/politika-za-poveritelnost">https://euro-tax.bg/politika-za-poveritelnost</a>.</li>
          <li>Клиентите могат да деактивират бисквитките в настройките на браузъра си. Деактивирането на бисквитките може да затрудни или предотврати използването на някои услуги.</li>
        </ol>
        <br />
        <p><strong> IX. Авторски Права</strong></p>
        <ol>
          <li>Използването на Мобилното приложение или Клиентския Панел не предоставя на Клиента собственически авторски права върху софтуера.</li>
          <li>Клиентът има забрана да:
            <ol type="a">
              <li>Модифицира, променя, подобрява или усъвършенства Мобилното Приложение или Клиентския Панел,</li>
              <li>Разпространява съдържание от Мобилното приложение или Клиентския панел извън платформата,</li>
              <li>Разпространява търговски или предоставя лицензи за използване на Мобилното Приложение или Клиентския Панел.</li>
            </ol>
          </li>
        </ol>
      `
    },

    etxRoTerms () {
      return `
        <p>Reglementări pentru utilizarea Panoului pentru clienți valabile de la 01/08/2021.</p>
        <p><strong> I. Definiție</strong></p>
        Termenii utilizați în regulamente înseamnă: </br>
        <ol>
          <li>Regulament - prezentul regulament.</li>
          <li>Client - o persoană care este parte la contract și utilizează Panoul pentru clienți.</li>
          <li>Panoul pentru clienți - software care permite conectarea utilizând dispozitive desktop și mobile, disponibil la  <a href="https://panoul.euro-tax.ro" >https://panoul.euro-tax.ro</a>.</li>
          <li>Număr client - o secvență de numere definite și trimise de furnizorul de servicii, datorită cărora Clientul se va putea conecta la Panoul clientului.</li>
          <li>Parolă temporară - o secvență de caractere trimise sub forma unui SMS către telefonul mobil al Clientului comunicat Furnizorului de servicii. Acesta permite prima conectare (autorizare) în Panoul clientului și este valabil timp de 14 zile de la primire.</li>
          <li>Parolă - un șir de caractere determinat independent de client în timpul procesului de înregistrare și în timpul utilizării în orice moment.</li>
          <li>Înregistrare - o activitate singulară care activează Panoul clientului utilizând datele comunicate de Furnizorul de servicii. Prezentarea cazurilor noi în Panoul pentru clienți este automată și nu necesită înregistrări suplimentare.</li>
          <li>Acord - un acord de mandat / cadru / ordin încheiat cu o companie selectată a Grupului de capital euro-fiscal. pl S.A.</li>
          <li>Furnizor de Servicii - o companie din Grupul de Capital Euro-Tax S.A., cu care Clientul a încheiat un acord pentru furnizarea serviciilor selectate.</li>
          <li>Capital Group - Euro-Tax Capital Group. pl. S.A.,  cuprinde următoarele societăți; CUF Sp. z o.o., Euro-Tax.pl Tax Refund S.A., Euro-Tax.ro Servicii Fiscale Emigranti SRL, Euro-Tax.bg EOOD.</li>
        </ol>
        <br />
        <p><strong> II. Dispoziții generale</strong></p>
        <ol>
          <li>Regulamentul definește regulile de utilizare a Panoului pentru clienți</li>
          <li>Panoul clienți este disponibil numai clienților furnizorului de servicii. În chestiunile care nu intră sub incidența regulamentelor,  se aplică dispozițiile Codului civil.</li>
          <li>În ceea ce privește accesul la Panoul pentru clienți, Furnizorul de servicii nu este un furnizor de servicii Internet. Clientul trebuie să obțină acces la o stație de calculator sau la un dispozitiv mobil cu acces la conexiunea la Internet pe cont propriu.</li>
          <li>Furnizorul de servicii are dreptul de a încredința subcontractantului întreținerea continuă a Panoului pentru clienți, fără a informa Clientul despre aceasta.</li>
          <li>Furnizorul de servicii își rezervă dreptul de a suspenda furnizarea Panoului pentru clienți către toți sau către unii clienți, în special din cauza necesității de întreținere periodică sau modificări software.</li>
          <li>Furnizorul de servicii își rezervă dreptul de a întrerupe complet Panoul clientului fără a oferi un motiv, după ce a informat Clienții prin e-mail cu privire la luarea unei astfel de decizii cu cel puțin 7 zile în avans.</li>
          <li>Furnizorul de servicii își rezervă dreptul de a modifica aceste Regulamente în orice moment. Modificările aduse regulamentelor intră în vigoare din momentul specificat în noile regulamente.</li>
        </ol>
        <br />
        <p><strong> III. Domeniul de aplicare și condițiile de utilizare a Panoului pentru clienți</strong></p>
        <ol>
          <li>Accesul la Panoul pentru clienți poate fi obținut numai de către Clienți care utilizează serviciile Furnizorului de servicii.</li>
          <li>Pentru buna funcționare a Panoului pentru clienți, se recomandă utilizarea versiunilor curente ale browserelor, Panoul client nu funcționează corect în browserul Internet Explorer.</li>
          <li>Furnizorul de Servicii isi rezerva dreptul de a modifica metoda tehnica de accesare si utilizare a Panoului de Clienti, in functie de domeniul de aplicare al autorizatiilor, posibilitatilor tehnice si regulilor de securitate.</li>
          <li>Furnizorul de servicii are dreptul de a posta publicitate, marketing, servicii și produse ale Furnizorului de servicii sau ale terților în Panoul clientului, care vor fi afișate Clientului cu condiția să își dea consimțământul corespunzător.</li>
          <li>Informațiile tehnice, de sistem și de întreținere privind funcționarea Panoului pentru clienți pot fi trimise la adresa de e-mail a Clientului comunicata Furnizorului de servicii.</li>
          <li>Destinatarul serviciului poate șterge contul în orice moment. În acest scop, vă rugăm să trimiteți un e-mail la suportclienti@euro-tax.ro.</li>
          <li>Furnizorul de servicii își rezervă dreptul de a bloca accesul la Panoul clientului pentru un Client care încalcă prevederile Regulamentului.</li>
          <li>Drepturile exclusive asupra conținutului furnizat ca parte a Panoului pentru clienți, în special drepturile de autor, numele și mărcile comerciale, alte denumiri comerciale, software-ul și drepturile asupra bazelor de date sunt protejate prin lege.</li>
        </ol>
        <br />
        <p><strong> IV. Înregistrare</strong></p>
        <ol>
          <li>Înregistrarea se poate face de către Clientul care va primi datele de conectare de la Furnizorul de servicii. Furnizorul de servicii se asigură că login-ul este unic.</li>
          <li>Pentru a vă înregistra trebuie:
            <ol type="a">
              <li>să  deschideți linkul de activare trimis în e-mail;</li>
              <li>să autorizați înregistrarea cu o Parolă temporară trimisă printr-un SMS la numărul de telefon comunicat Furnizorului de Servicii, în absența primirii Parolei temporare, solicitarea de acces trebuie autorizată cu un angajat al Departamentului de Relații cu Clienții;</li>
              <li>să definiți o nouă parolă cu care ca si  Client  vă veți putea conecta la Panou;</li>
              <li>să ciți și să acceptați regulamentele;</li>
            </ol>
          </li>
          <li>După confirmarea Înregistrării corecte, Clientul obține acces la Panoul clienți.</li>
          <li>Un client care a încheiat mai multe contracte cu una sau mai multe companii diferite ale Grupului Capital va obține acces la date privind toate contractele după Înregistrare.</li>
        </ol>
        <br />
        <p><strong> V. Acces după înregistrare</strong></p>
        <ol>
          <li>Panoul pentru clienți permite Clientului, după înregistrare, conectare și acceptare a Regulamentului, accesul la funcții și informații privind serviciile furnizate de Furnizorul de Servicii, în special:
            <ol type="a">
              <li>prezentări de cazuri în pagina Cazurile mele;</li>
              <li>prezentări ale celor mai importante informații din pagina Știri;</li>
              <li>prezentarea detaliilor de contact în pagina Contact;</li>
              <li>prezentări ale etapei de decontare, Istoric caz, Sume estimate de rambursare / plată insuficientă, Timp mediu de așteptare, Documente de caz, posibilitatea de a edita detaliile contului pentru caz, în Detalii caz;</li>
              <li>posibilitatea de a edita datele de contact, detaliile contului bancar, parola pentru a vă conecta la Profilul clientului;</li>
              <li>afișarea datelor referitoare la cazurile din istoricul și mesajele de caz; valoarea plăților, valoarea datoriilor, termenele stabilite de oficiile și instituțiile străine și poloneze și altele;</li>
              <li>afișarea în documentele de caz a anexelor, ar fi un acord, declarații privind conturile bancare, facturile și alte scrisori legate de decontare, inclusiv corespondența și deciziile birourilor și instituțiilor poloneze și străine.</li>
            </ol>
          </li>
          <li>Clientul este obligat în special:
            <ol type="a">
              <li>să nu ia măsuri precum: punerea Contului la dispoziția terților, asumarea unor terțe părți, transferul dreptului de utilizare a Contului către terțe părți, luarea de it sau alte activități menite să obțină numerele clienților sau parolele altor clienți;</li>
              <li>păstrarea confidențialității datelor necesare pentru  conectarea la Panoul pentru clienți (în special parola) și pentru a exercita cea mai mare atenție pentru a împiedica terții să intre în posesia acestora;</li>
              <li>să utilizeze Grupul pentru clienți într-un mod compatibil cu dispozițiile legislației aplicabile, cu dispozițiile regulamentului, precum și cu obiceiurile adoptate într-un anumit domeniu de aplicare;</li>
              <li>să notifice imediat Furnizorul de servicii cu privire la orice încălcare a drepturilor sale de către terți, precum și cu privire la orice încălcare cunoscută a regulilor prevăzute în Regulament de către terți. </li>
            </ol>
          </li>
          <li>Furnizorul de servicii poate priva Clientul de dreptul de a utiliza Panoul clientului sau de a limita vizibilitatea cazurilor și atașamentelor prezentate (prin blocarea sau ștergerea Contului său) în cazul unei încălcări de către Utilizator a Regulamentului, în special atunci când Clientul a furnizat Furnizorului de servicii date neadevărate, inexacte sau depășite , introducerea unei erori sau încălcarea drepturilor terților și ale Furnizorului de servicii, precum și în situația în care stocarea datelor pentru un anumit serviciu este nejustificată din punct de vedere juridic din partea Furnizorului de servicii.</li>
          <li>Conturile neutilizate p<p><strong> Termenii și condițiile Campaniei Promoționale "Reducere pentru Semnarea Contractului de către Panoul Clienților"</strong></p>
        <p>Data intrării în vigoare a regulamentului: 20.12.2024</p>
        <p>Data valabilității regulamentului: 20.12.2025</p>
        <p><strong>§1 Dispoziții generale</strong></p>
        <ol>
          <li>Organizatorul campaniei promoționale "Reducere pentru Semnarea Contractului in Panoul Clienților" (denumit în continuare: "Campanie") este Euro-Tax.RO Servicii Fiscale Emigranți S.R.L., cu sediul în OTOPENI, Drumul Gării Odăi, Nr. 1 A, INTRAREA A, CUI RO28964928, Nr. In Registrul Comerțului J23/5734/17.09.2021., denumit în continuare în regulament Organizatorul.</li>
          <li>Campania își propune să promoveze utilizarea Panoului Clienților ca canal de încheiere a contractelor cu Organizatorul privind:
            <ol type="a">
              <li>Depunerea unei cereri sau cererilor de decontare a impozitului pe venit al Mandantului împreună cu documentația necesară la biroul fiscal competent local dintr-o altă țară decât România,</li>
              <li>Depunerea unei cereri sau a unor cereri de acordare și plată a unei indemnizații sau a unei indemnizații către Principal, împreună cu documentația necesară, la biroul competent local dintr-o altă țară decât România.</li>
            </ol>
          </li>
          <li>Regulamentul precizează regulile de participare la Campanie, condițiile de acordare a reducerilor și regulile de decontare.</li>
        </ol>
        <p><strong>§2 Participanții la campanie</strong></p>
        <ol>
          <li>Un Participant la Campanie (denumit în continuare: "Participant") poate fi orice persoană fizică sau juridică care:
            <ol type="a">
              <li>a încheiat un Acord-cadru de cooperare cu Organizatorul prin intermediul Panoului pentru clienți și/sau</li>
              <li>a încheiat un Acord de Comisionare/a plasat o Comandă prin intermediul Panoului Clientului și</li>
              <li>îndeplinește celelalte condiții specificate în regulament.</li>
            </ol>
          </li>
          <li>Aderarea la Campanie este automată după îndeplinirea condițiilor specificate în prezentul Regulament, fără a fi nevoie ca Participantul să depună declarații suplimentare.</li>
        </ol>
        <p><strong>§3 Principii de acordare a unei reduceri</strong></p>
        <ol>
          <li>Ca parte a Campaniei, Participantul are dreptul la o reducere unică, a cărei sumă este stabilită individual de către Organizator și comunicată Participantului în Panoul Clienților la încheierea Contractului de Comisionare/plasarea unei Comenzi.</li>
          <li>Campania promoțională se adresează persoanelor fizice cu capacitate juridică deplină cu reședința în România, care au lucrat în următoarele țări: Germania, Olanda, Marea Britanie, Irlanda, Belgia, Austria și au dreptul la rambursarea impozitului pe venit din străinătate sau decid să plătească comisionul în avans în cazul unor sume mici de rambursare sau a plății insuficiente a impozitului pe venit.</li>
          <li>Reducerea va fi inclusă în decontarea finală cu Participantul, cu condiția îndeplinirii următoarelor condiții:
            <ol type="a">
              <li>Acordul-cadru de cooperare a fost încheiat prin intermediul panelului de clienți;</li>
              <li>Participantul a încheiat Acordul de Comisionare/a plasat o Comandă prin intermediul Panoului de Clienți,</li>
              <li>Participantul a acceptat valoarea reducerii acordate la încheierea Acordului de Comision/plasarea unei Comenzi pe Panoul Clienților,</li>
              <li>Cazul care face obiectul Acordului/Ordinului Comisiei a fost transmis Autorității competente de către Organizator.</li>
              <li>Autoritatea care emite decizia menționată mai sus a încheiat procedura cu o decizie pozitivă sau o altă decizie care permite soluționarea între Organizator și Participant.</li>
            </ol>
          </li>
          <li>Reducerea nu se acordă dacă:
            <ol type="a">
              <li>Acordul-cadru sau Acordul Comisiei a fost încheiat într-un alt mod decât prin intermediul Panoului pentru clienți sau Comanda a fost plasată într-un alt mod decât prin intermediul Panoului pentru clienți,</li>
              <li>cazul nu a fost transmis autorității competente din motive imputabile participantului;</li>
              <li>autoritatea competentă nu a emis o decizie.</li>
            </ol>
          </li>
          <li>Reducerea se acordă prin reducerea comisionului datorat Organizatorului, în conformitate cu prevederile Contractului de comision încheiat cu Participantul sau cu termenii Comenzii plasate.</li>
        </ol>
        <p><strong>§4 Date cu caracter personal și cookie-uri</strong></p>
        <ol>
          <li>Organizatorul de servicii este administratorul datelor cu caracter personal, care pot fi, de asemenea, partajate cu entități afiliate Furnizorului de servicii.</li>
          <li>Scopul prelucrării datelor în Panoul Clientului este de a le prezenta Clientului în legătură cu executarea serviciului. Mai multe informații pot fi găsite la: https://euro-tax.ro/termeni-si-conditii/.</li>
          <li>Cookie-urile sunt utilizate pe paginile Panoului clientului. Utilizarea Panoului Clientului fără a modifica setările browserului înseamnă că cookie-urile vor fi stocate pe dispozitivul Clientului. Mai multe detalii: https://euro-tax.ro/termeni-si-conditii/.</li>
          <li>Clienții pot dezactiva cookie-urile în setările browserului lor. Cu toate acestea, dezactivarea cookie-urilor poate împiedica sau împiedica utilizarea anumitor servicii.</li>
        </ol>
        <p><strong>§ Dispoziții finale</strong></p>
        <ol>
          <li>Organizatorul poate suspenda sau anula valabilitatea Campaniei în orice moment, cu condiția ca suspendarea sau anularea Campaniei să nu afecteze drepturile Participanților dobândite înainte de data suspendării sau anulării Campaniei.</li>
          <li>Organizatorul își rezervă dreptul de a modifica termenii Campaniei în orice moment, în special de a modifica valoarea reducerilor acordate, cu condiția ca modificările să nu afecteze drepturile Participanților dobândite înainte de introducerea lor.</li>
          <li>Regulamentul Campaniei este disponibil pe site-ul Organizatorului și în Panoul Clienților.</li>
          <li>În domeniile care nu sunt reglementate de prezentul regulament, se aplică dispozițiile legislației poloneze, în special Codul civil.</li>
          <li>Prin participarea la Campanie, Participantul confirmă că este de acord să participe la aceasta în condițiile specificate în Regulament.</li>
        </ol>entru o perioadă mai mare de 12 luni de la ultima conectare pot fi șterse de furnizorul de servicii fără notificarea prealabilă a Clientului.</li>
          <li>Furnizorul de servicii recomandă Clientului să modifice parola la fiecare 30 de zile pentru a menține securitatea datelor.</li>
          <li>Clientul este obligat:
            <ol type="a">
              <li>să furnizeze  date veridice, exacte și actualizate, care nu induc în eroare și nu încalcă drepturile terților, în special datele de contact și datele privind numărul de cont bancar. </li>
              <li>să actualizeze datele de contact imediat după fiecare modificare a acestor date.</li>
            </ol>
          </li>
        </ol>
        <br />
        <p><strong> VI. Responsabilitate</strong></p>
        <ol>
          <li>Furnizorul de servicii nu este responsabil pentru problemele tehnice sau limitările tehnice ale echipamentului informatic, dispozitivului final, sistemului TIC și infrastructurii de telecomunicații utilizate de Client, care împiedică utilizarea corectă a Panoului pentru clienți.</li>
          <li>Clientul este răspunzător pentru actele sau omisiunile unei alte entități, căreia ii permite să utilizeze Contul său, precum și pentru propriile acțiuni sau omisiuni.</li>
          <li>Cu excepția cazului în care dispozițiile legale obligatorii prevăd altfel, Furnizorul de servicii nu va fi răspunzător pentru:
            <ol type="a">
              <li>consecintele negative ale ne-delogarii de catre Client a Panoului de Clienti dupa incheierea sesiunii;</li>
              <li>efectele utilizării Panoului clientului de către Client într-un mod incompatibil cu legislația aplicabilă, cu prevederile Regulamentului și cu posibilele daune asociate acestuia;</li>
              <li>efectele utilizării adresei de e-mail și / sau a numărului de telefon al persoanei care nu a consimțit la aceasta în timpul procesului de înregistrare de către o persoană neautorizată.</li>
            </ol>
          </li>
        </ol>
        <br />
        <p><strong> VII. Litigii și reclamații</strong></p>
        <ol>
          <li>Eventualele litigii contractuale vor fi soluționate de instanțele comune. Clientul are dreptul de a utiliza mijloace extrajudiciare de soluționare a reclamațiilor și a căilor de atac.</li>
          <li>În chestiunile care nu intră sub incidența prezentului regulament, se aplică dispozițiile relevante din Codul civil.</li>
          <li>Clientul poate raporta Furnizorului de servicii orice nereguli legate de funcționarea Panoului pentru clienți, în special în scris sau prin e-mail, trimițând un e-mail la adresa <a href="mailto:suportclienti@euro-tax.ro" >suportclienti@euro-tax.ro</a>. Reclamațiile sunt luate în considerare în termen de 30 de zile lucrătoare de la primirea e-mailului.</li>
        </ol>
        <br />
        <p><strong> VIII. Date cu caracter personal și cookie-uri</strong></p>
        <ol>
          <li>Administratorul datelor cu caracter personal este Furnizorul de Servicii, dar aceste date pot fi puse la dispoziția altor entități legate prin capital furnizorului de servicii, adică aparținând Capital Group</li>
          <li>Scopul prelucrării datelor este de a permite Clientului să acceseze informații privind serviciile comandate de Furnizorul de Servicii, în special serviciile indicate în Capitolul 5, par. 1 din Regulament.</li>
          <li>Clientul are dreptul de a accesa propriile date cu caracter personal, de a le rectifica, de a le șterge, de a limita prelucrarea, de a transfera, de a se opune prelucrării și de a depune o plângere la organismul de supraveghere.</li>
          <li>Clientul poate citi informațiile complete privind prelucrarea datelor cu caracter personal de către Furnizorul de servicii pe site-ul web la  <a href="https://euro-tax.ro/termeni-si-conditii/" >https://euro-tax.ro/termeni-si-conditii/</a></li>
          <li>Cookie-urile sunt utilizate pe paginile Panoului pentru clienți. Utilizarea Panoului pentru clienți fără a modifica setările browserului înseamnă că cookie-urile vor fi plasate pe dispozitivul Clientului. Puteți găsi mai multe informații despre acest subiect aici;  <a href="https://euro-tax.ro/termeni-si-conditii/" >https://euro-tax.ro/termeni-si-conditii/</a></li>
          <li>Fiecare client poate dezactiva mecanismul "cookies" în browserul web al dispozitivului său final. Contractantul indică faptul că dezactivarea "cookie-urilor" poate provoca dificultăți sau poate împiedica utilizarea Panoului pentru clienți.</li>
        </ol>
        <br />
        <p><strong> IX. Dispoziții finale</strong></p>
        <ol>
          <li>Instanța competentă să examineze litigiile este instanța competentă pentru adresa sediului Furnizorului de servicii.</li>
          <li>Regulamentul este valabil de la 01/08/2021.</li>
        </ol>
      `
    }
  },
  methods: {
    agreeTerms () {
      this.$http.put(`/client_accounts/${this.currentClient.id}/accept_terms/`)
        .then(() => this.$http.get(`/client_accounts/${this.currentClient.id}/refresh_token/`))
        .then(response => {
          AppStorage.set('token', response.data.token)
          localStorage.token = response.data.token
          this.$store.dispatch('login', response.data.token)
        })
        .then(() => {
          this.dialog = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    cancel () {
      this.$store.dispatch('closeShowTerms')
    },
    logout () {
      this.$http.delete('auth')
        .then(() => AppStorage.remove('token'))
        .then(() => {
          this.$store.dispatch('logout')
          this.$router.push('/login?type=logout')
        })
    }
  }
}
</script>

<style lang="scss">
  .hr-line {
    margin-bottom: -15px;
  }
</style>
