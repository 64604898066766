import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import documents from './modules/documents'
import spinner from './modules/spinner'
import messages from './modules/messages'
import client from './modules/client'
import info from './modules/info'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    documents,
    spinner,
    messages,
    client,
    info
  }
})

export default store
