<template>
  <v-app>
    <v-navigation-drawer v-if="currentClient" app>
      <div class="container text-center mt-8">
        <router-link class="mt-4" :to="'/documents'">
          <img class="logo" :src="logoSvg" :alt="logoAlt" />
        </router-link>
      </div>
      <div class="container text-center mt-8" v-if="specialOffer">
        <router-link class="mt-4" :to="'/documents/' + specialOffer.id + '/general'">
          <button
            class="offer-button text-left mb-2"
          >
            <div class="row">
              <button class="offer-icon ml-6 mr-4"></button>
              <span v-html="$t('offer_button', { year: specialOffer.year })"></span>
            </div>
          </button>
        </router-link>
      </div>
      <div class="mt-16 text-center">
        <router-link :to="'/documents'">
          <button
            class="main-button text-left mb-2"
          >
            <div class="row">
              <button class="documents-icon ml-6 mr-4"></button>
              {{ $t('my_cases') }}
            </div>
          </button>
        </router-link>
        <router-link class="mb-2" :to="'/messages'">
          <button
            class="main-button text-left mb-2"
          >
            <div class="row">
              <button class="messages-icon ml-6 mr-4"></button>
              {{ $t('messages') }}
              <span class="messages-square-sidebar ml-2">{{ unreadMessagesSize }}</span>
            </div>
          </button>
        </router-link>
        <router-link class="mb-2" :to="'/contact'">
          <button
            class="main-button text-left mb-2"
          >
            <div class="row">
              <button class="contact-icon ml-6 mr-4"></button>
              {{ $t('contact') }}
            </div>
          </button>
        </router-link>
        <button
          class="main-button text-left mt-16"
        >
          <div class="row" @click="logOut()">
            <button class="logout-icon ml-6 mr-4"></button>
            {{ $t('logout') }}
          </div>
        </button>
      </div>
    </v-navigation-drawer>

    <v-container fluid>
      <v-row style="height: 100%">
        <v-col :class="showUserProfile ? 'col col-9 pa-0' : 'col col-12 pa-0'">
          <div v-if="currentClient" class='fixed-row d-none d-lg-inline'></div>
          <div v-if="currentClient" class='container mt-3 pt-6 fixed-row-with-logo d-inline d-lg-none'>
            <v-menu class="burger-button" v-model="menuOpen" :position-x="menuX" :position-y="menuY">
              <template v-slot:activator="{ on }">
                <v-app-bar-nav-icon v-on="on"></v-app-bar-nav-icon>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title class="mobile-menu-item-title" @click="$router.push({ path: '/dashboard' })">
                    <button class="mobile-menu-item start-icon mr-3 mt-3"></button>
                    {{ $t('start') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title class="mobile-menu-item-title" @click="$router.push({ path: '/newDocument' })">
                    <button class="mobile-menu-item plus-icon mr-3 mt-3"></button>
                    {{ $t('add_settlement') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title class="mobile-menu-item-title" @click="$router.push({ path: '/documents' })">
                    <button class="mobile-menu-item documents-icon mr-3 mt-3"></button>
                    {{ $t('my_cases') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title class="mobile-menu-item-title" @click="$router.push({ path: '/messages' })">
                    <button class="mobile-menu-item messages-icon mr-3 mt-3"></button>
                    {{ $t('messages') }}
                    <span class="messages-square-sidebar messages-square-sidebar-mobile ml-2">{{ unreadMessagesSize }}</span>
                  </v-list-item-title>
                </v-list-item>
                <!-- <v-list-item>
                  <v-list-item-title @click="$router.push({ path: '#' })">
                    <button class="mobile-menu-item share-icon mr-3 mt-3"></button>
                    {{ $t('recommend_and_earn') }}
                  </v-list-item-title>
                </v-list-item> -->
                <!-- <v-list-item>
                  <v-list-item-title @click="$router.push({ path: '#' })">
                    <button class="mobile-menu-item settings-icon mr-3 mt-3"></button>
                    {{ $t('settings') }}
                  </v-list-item-title>
                </v-list-item> -->
                <v-list-item>
                  <v-list-item-title class="mobile-menu-item-title" @click="logOut()">
                    <button class="mobile-menu-item logout-icon mr-3 mt-3"></button>
                    {{ $t('logout') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <v-app-bar class="d-none d-lg-inline" v-if="currentClient" app>
            <div :class="showUserProfile ? 'col col-7 col-md-8 col-xl-9' : 'col col-12'" class="text-right d-none d-lg-inline">
              <router-link class="messages-link" :to="'/messages'">
                <v-badge
                  v-if="unreadMessagesSize > 0"
                  color="#D20F6E"
                  dot
                  overlap
                >
                  <button class="messages-icon"></button>
                </v-badge>

                <button v-if="unreadMessagesSize === 0" class="messages-icon"></button>
              </router-link>

              <v-icon @click="displayUserProfile" class="ml-4 account-icon"></v-icon>
            </div>
          </v-app-bar>

          <!-- Sizes your content based upon application components -->
          <v-main style="height: 102%">

            <!-- Provides the application the proper gutter -->
            <v-container class="pa-1 pa-lg-3 mt-0 pt-2 pt-lg-0" fluid>
              <router-view/>
            </v-container>
          </v-main>
        </v-col>
        <v-col cols="3" v-if="showUserProfile">
          <userProfile/>
        </v-col>
      </v-row>
    </v-container>
    <updateContactDetailsModal v-if="showUpdateContactDetailsModal"></updateContactDetailsModal>
    <updateAccountNumberMultipleDocumentsModal v-if="showUpdateAccountNumberMultipleDocumentsModal"></updateAccountNumberMultipleDocumentsModal>
    <changePasswordModal v-if="showChangePassword"></changePasswordModal>
    <termsModal v-if="currentClient && currentClient.registered && !currentClient.reset_password && !currentClient.accept_terms "></termsModal>
    <marketingConsentModal v-if="currentClient && currentClient.registered && !currentClient.reset_password && currentClient.accept_terms && !currentClient.filled_marketing_consent_date"></marketingConsentModal>
    <termsModal v-bind:readOnly="true" v-if="showTerms"></termsModal>
    <update-notification />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import termsModal from '@/components/termsModal'
import marketingConsentModal from '@/components/marketingConsentModal'
import userProfile from '@/components/userProfile'
import updateContactDetailsModal from '@/components/updateContactDetailsModal'
import updateAccountNumberMultipleDocumentsModal from '@/components/updateAccountNumberMultipleDocumentsModal'
import changePasswordModal from '@/components/changePasswordModal'
import LogRocket from 'logrocket'
import { AppStorage, analyticsModule, getIsExpoApp } from 'cuf-expo-bridge'
import UpdateNotification from '@/components/UpdateNotification'
import { recoverUtm } from '@/utils/recoverUtm'

export default {
  name: 'app',

  data () {
    return {
      menuOpen: false,
      menuX: 0,
      menuY: 0
    }
  },
  computed: {
    ...mapGetters(['currentClient']),
    ...mapGetters(['unreadMessagesSize']),
    ...mapGetters(['showUpdateContactDetailsModal']),
    ...mapGetters(['showUpdateAccountNumberMultipleDocumentsModal']),
    ...mapGetters(['showUserProfile']),
    ...mapGetters(['showChangePassword']),
    ...mapGetters(['showTerms']),
    ...mapGetters(['specialOffer']),

    logoSvg () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return require('@/assets/euro-tax.ro.svg')
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return require('@/assets/euro-tax.bg.svg')
      } else if (this.$i18n.locale === 'pl' || location.host.includes('.euro-tax.pl')) {
        if (this.$i18n.locale === 'ro') {
          return require('@/assets/euro-tax.ro.svg')
        } else if (this.$i18n.locale === 'bg') {
          return require('@/assets/euro-tax.bg.svg')
        } else if (this.currentClient && this.currentClient.agreement_with === 'CUF') {
          return require('@/assets/cuf-logo.svg')
        } else {
          return require('@/assets/euro-tax.pl.svg')
        }
      } else {
        return require('@/assets/cuf-logo.svg')
      }
    },
    logoAlt () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return 'Client Panel Euro-tax'
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return 'Клиентски панел Euro-tax'
      } else if (this.$i18n.locale === 'pl' && (this.currentClient && this.currentClient.agreement_with === 'CUF')) {
        return 'Panel Klienta CUF'
      } else {
        return 'Panel Klienta Euro-tax'
      }
    }
  },
  components: {
    termsModal,
    userProfile,
    updateContactDetailsModal,
    updateAccountNumberMultipleDocumentsModal,
    changePasswordModal,
    marketingConsentModal,
    UpdateNotification
  },
  mounted () {
    this.detectBrowserLanguage()
  },
  created () {
    const favicon = document.getElementById('favicon')

    if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
      favicon.href = 'https://euro-tax.ro/assets/dist/img/favicons/favicon-16x16.png'
    } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
      favicon.href = 'https://euro-tax.bg/assets/dist/img/favicons/favicon-16x16.png'
    } else if (this.$i18n.locale === 'pl' && (this.currentClient && this.currentClient.agreement_with === 'CUF')) {
      favicon.href = 'https://cuf-podatki.pl/themes/cuf/favicon/favicon-16x16.png'
    } else {
      favicon.href = 'https://euro-tax.pl/assets/dist/img/favicons/favicon-16x16.png'
    }

    this.$nextTick(function () {
      if (this.currentClient && this.currentClient.id) {
        this.$http.get(`/client_accounts/${this.currentClient.id}/refresh_token/`)
          .then(response => {
            localStorage.token = response.data.token
            this.$store.dispatch('login', response.data.token)
          })

        window.setInterval(() => {
          this.$http.get(`/client_accounts/${this.currentClient.id}/check_inactivity/`)
            .then(async request => {
              if (request.data) {
                await AppStorage.remove('token')
                this.$store.dispatch('logout')
                this.$router.push('/login?type=autologout')
              }
            })
        }, 60 * 1000)

        this.$store.dispatch('getMessages')
        this.$store.dispatch('getInfo')
      }
    })
    if (this.currentClient) {
      if (process.env.NODE_ENV === 'production') {
        const isMobile = /Mobi|Android/i.test(navigator.userAgent)
        const logRocketKey = isMobile ? '6m0uyc/pk-sprzedaz' : 'kizamx/panel'

        LogRocket.init(logRocketKey)

        LogRocket.identify(this.currentClient.id, {
          login: this.currentClient.login
        })
      }
    }
  },
  methods: {
    detectBrowserLanguage () {
      // Check if userLanguage is stored in localStorage
      const storedLanguage = localStorage.getItem('userLanguage')
      if (storedLanguage) {
        this.$i18n.locale = storedLanguage
        console.log(`Detected language from localStorage: ${storedLanguage}`)
        return
      }

      // Default to Polish
      let languageCode = 'pl'
      const supportedLanguages = ['pl', 'ro', 'bg']

      // Detect from domain
      const domainLanguageMap = {
        '.ro': 'ro',
        '.bg': 'bg'
      }

      // Check domain for language code
      for (const domain in domainLanguageMap) {
        if (location.host.includes(domain)) {
          languageCode = domainLanguageMap[domain]
          break
        }
      }

      // Fallback to browser language if domain doesn't determine language
      if (languageCode === 'pl') {
        const userLanguage = navigator.language || navigator.userLanguage
        const browserLanguage = userLanguage.split('-')[0] // Get base language code

        if (!supportedLanguages.includes(browserLanguage)) {
          // Redirect to language selection page if language not supported
          this.$nextTick(function () {
            this.$router.push('/select-language')
          })
          return
        }

        if (['ro', 'bg'].includes(browserLanguage)) {
          languageCode = browserLanguage
        }
      }

      // Set the detected language in vue-i18n
      this.$i18n.locale = languageCode
      analyticsModule.initAnalytics({
        title: this.$t('analytics.title'),
        acceptAll: this.$t('analytics.acceptAll'),
        denyAll: this.$t('analytics.denyAll'),
        save: this.$t('analytics.save'),
        consents: {
          analyticsStorage: this.$t('analytics.consents.analyticsStorage'),
          adStorage: this.$t('analytics.consents.adStorage'),
          adUserData: this.$t('analytics.consents.adUserData'),
          adPersonalization: this.$t('analytics.consents.adPersonalization')
        }
      }).then(() => {
        recoverUtm().then(data => {
          analyticsModule.logEvent({ name: 'campaign_details', params: data })
          if (getIsExpoApp()) {
            localStorage.setItem('utmSource', data.source)
          }
        })
      })

      console.log(`Detected language: ${languageCode}`)
    },
    async logOut () {
      await AppStorage.remove('token')
      this.$store.dispatch('logout')
      this.$router.push('/login?type=logout')
      this.menuOpen = false
    },
    displayUserProfile () {
      this.$store.dispatch('displayShowUserProfile')
    }
  }
}
</script>

<style lang="scss">

  .mobile-menu-item-title {
    line-height: 1.8 !important;
  }

  h1, h2, h3, h4, h5 {
    word-break: break-word;
  }

  .orange-gradient {
    background: transparent linear-gradient(270deg, #F57921 0%, #F5B521 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 14px 20px;
    height: 100%;
    color: white;
  }

  .logo {
    height: 30px;
  }

  .base-info-description {
    font-size: 14px;
    line-height: 21px;
    text-align: justify;
  }

  .gradient-divider {
    margin: 20px auto;
    text-align: center;
    height: 2px;
    background: linear-gradient(270deg, #D20F6E 0%, #F57921 101.54%);
  }

  .numeric-base-form-input {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    font-size: 16px;
    background-color: rgba(180, 180, 180, 0.1) !important;
  }

  .base-form-input {
    fieldset {
      color: transparent !important;
    }

    .v-input__slot {
      background-color: rgba(180, 180, 180, 0.1) !important;
      border-radius: 10px;
      border: none;
    }
  }

  .square-description {
    color: #707070;
    font-size: 14px;
  }

  // Firefox only
  .documents-symbol {
    @-moz-document url-prefix() {
      margin-right: 5px;
    }
  }

  .account-icon {
    background: transparent url('./assets/icons/account-icon-black.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
    margin-top: -10px;

    // Firefox only
    @-moz-document url-prefix() {
      margin-top: 7px;
    }
  }

  .account-icon:hover {
    background: transparent url('./assets/icons/account-icon-purple.svg') 0% 0% no-repeat padding-box;
    cursor: pointer;
  }

  .messages-link {
    color: transparent;

    .v-badge__wrapper {
      margin-left: 3px;
      margin-top: -3px;
    }
  }

  .messages-icon {
    background: transparent url('./assets/icons/message-icon-black.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
  }

  .envelope-icon {
    background: transparent url('./assets/icons/envelope.svg') 0% 0% no-repeat padding-box;
    width: 24px;
    height: 24px;
  }

  .envelope-urgent-icon {
    background: transparent url('./assets/icons/envelope-urgent.svg') 0% 0% no-repeat padding-box;
    width: 24px;
    height: 24px;
  }

  .checkbox-icon {
    background: transparent url('./assets/icons/checkbox.svg') 0% 0% no-repeat padding-box;
    width: 24px;
    height: 24px;
  }

  .documents-icon {
    background: transparent url('./assets/icons/document-icon-black.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
  }

  .phone2-icon {
    background: transparent url('./assets/icons/phone2.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
  }

  .search-white-icon {
    background: transparent url('./assets/icons/search-white.svg') 0% 0% no-repeat padding-box;
    width: 30px;
    height: 30px;
  }

  .checked-mark-icon {
    background: transparent url('./assets/icons/checked-mark.svg') 0% 0% no-repeat padding-box;
    width: 30px;
    height: 30px;
  }

  .send-email-icon {
    background: transparent url('./assets/icons/send-email.svg') 0% 0% no-repeat padding-box;
    width: 30px;
    height: 30px;
  }

  .download-agreement-icon {
    background: transparent url('./assets/icons/download-agreement.svg') 0% 0% no-repeat padding-box;
    width: 30px;
    height: 30px;
  }

  .chat-icon {
    background: transparent url('./assets/icons/chat.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
  }

  .person-data-icon {
    background: transparent url('./assets/icons/person_data.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
  }

  .settings-icon {
    background: transparent url('./assets/icons/settings.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
  }

  .logout-icon {
    background: transparent url('./assets/icons/logout-icon-black.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
  }

  .share-icon {
    background: transparent url('./assets/icons/share.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
  }

  .plus-icon {
    background: transparent url('./assets/icons/plus.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
  }

  .start-icon {
    background: transparent url('./assets/icons/start.svg') 0% 0% no-repeat padding-box;
    width: 20px;
    height: 20px;
  }

  .gradient-pink {
    background: linear-gradient(270deg, #D20F6E 0%, #F57921  101.54%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .v-toolbar__content {
    background-color: white;

    .messages-icon {
      background: transparent url('./assets/icons/message-icon-black.svg') 0% 0% no-repeat padding-box;
      width: 20px;
      height: 20px;
    }

    .messages-icon:hover {
      background: transparent url('./assets/icons/message-icon-orange.svg') 0% 0% no-repeat padding-box;
      width: 20px;
      height: 20px;
    }
  }

  .offer-button {
    background: transparent linear-gradient(270deg, #F57921 0%, #F5B521 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    width: 220px;
    height: 68px;
    color: white !important;
    -webkit-animation: glowing 1500ms infinite;
    -moz-animation: glowing 1500ms infinite;
    -o-animation: glowing 1500ms infinite;
    animation: glowing 1500ms infinite;

    .offer-icon {
      background: transparent url('./assets/icons/exclamation-mark-icon.svg') 0% 0% no-repeat padding-box;
      width: 20px;
      height: 20px;
      margin-top: 5px;
    }

    @-webkit-keyframes glowing {
      0% { background: #F57921; -webkit-box-shadow: 0 0 3px #F57921; }
      50% { background: #F5B521; -webkit-box-shadow: 0 0 40px #F5B521; }
      100% { background: #F57921; -webkit-box-shadow: 0 0 3px #F57921; }
    }

    @-moz-keyframes glowing {
      0% { background: #F57921; -moz-box-shadow: 0 0 3px #F57921; }
      50% { background: #F5B521; -moz-box-shadow: 0 0 40px #F5B521; }
      100% { background: #F57921; -moz-box-shadow: 0 0 3px #F57921; }
    }

    @-o-keyframes glowing {
      0% { background: #F57921; box-shadow: 0 0 3px #F57921; }
      50% { background: #F5B521; box-shadow: 0 0 40px #F5B521; }
      100% { background: #F57921; box-shadow: 0 0 3px #F57921; }
    }

    @keyframes glowing {
      0% { background: #F57921; box-shadow: 0 0 3px #F57921; }
      50% { background: #F5B521; box-shadow: 0 0 40px #F5B521; }
      100% { background: #F57921; box-shadow: 0 0 3px #F57921; }
    }
  }

  .offer-button:hover {
    background: #f5ab21;
  }

  .main-button-fixed,
  .main-button-fixed:hover {
    width: 186px !important;
  }

  .messages-square-sidebar-mobile {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    border-radius: 5px !important;
    color: #F57921;
    opacity: 1;
    background: linear-gradient(white, white) padding-box,
              linear-gradient(to right, #F57921, #D20F6E) border-box !important;
    border: 1px solid transparent !important;
  }

  .main-button-gradient {
    background: linear-gradient(270deg, #D20F6E 0%, #F57921  101.54%);
    color: white !important;
    border-radius: 10px;
    opacity: 1;
    width: 129px !important;
    height: 40px;
    &:hover {
      background: #d20e6e;
      color: white !important;
      width: 129px !important;
    }
  }

  .main-button-gradient-full-width {
    width: 100% !important;
    height: 48px !important;
    &:hover {
      width: 100% !important;
      height: 48px !important;
    }
  }

  .main-button-white-small {
    background: white;
    border-radius: 10px;
    opacity: 1;
    width: 129px !important;
    height: 40px;
    &:hover {
      background: white;
      color: white !important;
      width: 129px !important;
    }

    &:disabled {
      span {
        background: grey;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: not-allowed;
      }
    }

    span {
      background: linear-gradient(90deg, #F57921 0.05%, #D2116E 76.54%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .main-button-white {
    background: white;
    color: #f59520 !important;
    border-radius: 10px;
    opacity: 1;
    width: 186px;
    height: 55px;
    &:hover {
      background: #d20e6e;
      color: white !important;
    }
  }

  .main-button-purple {
    background: transparent linear-gradient(270deg, #9f004e 0%, #d20e6e 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    width: 220px;
    height: 68px;
    color: white !important;
    &:hover {
      background: #d20e6e;
    }
  }

  .main-button-purple:disabled,
  .main-button-purple[disabled]{
    background: none !important;
    background-color: #cccccc !important;
    color: #666666 !important;
    border-radius: 10px;
    cursor: not-allowed;
  }

  .v-navigation-drawer__content {
    .main-button {
      background: none !important;
      opacity: 1;
      width: 220px;
      height: 55px;
      color: #3D3D3F !important;

      .messages-square-sidebar {
        border: 1px solid #b4b4b4;
        border-radius: 5px;
        padding: 1px 7px;;
        color: #b4b4b4;
        font-size: 12px;
      }

      .documents-icon {
        background: transparent url('./assets/icons/document-icon-black.svg') 0% 0% no-repeat padding-box;
        width: 20px;
        height: 20px;
      }

      .logout-icon {
        background: transparent url('./assets/icons/logout-icon-black.svg') 0% 0% no-repeat padding-box;
        width: 20px;
        height: 20px;
      }

      .contact-icon {
        background: transparent url('./assets/icons/contact-icon-black.svg') 0% 0% no-repeat padding-box;
        width: 20px;
        height: 20px;
      }
    }

    .main-button:hover {
      background: #D20F6E !important;
      color: white !important;

      .messages-square-sidebar {
        border: 1px solid white;
        border-radius: 5px;
        padding: 1px 7px;;
        color: #D20F6E;
        font-size: 12px;
        background-color: white;
      }

      .documents-icon {
        background: transparent url('./assets/icons/document-icon.svg') 0% 0% no-repeat padding-box;
        width: 20px;
        height: 20px;
      }

      .messages-icon {
        background: transparent url('./assets/icons/message-icon.svg') 0% 0% no-repeat padding-box;
        width: 20px;
        height: 20px;
      }

      .logout-icon {
        background: transparent url('./assets/icons/logout-icon.svg') 0% 0% no-repeat padding-box;
        width: 20px;
        height: 20px;
      }

      .contact-icon {
        background: transparent url('./assets/icons/contact-icon.svg') 0% 0% no-repeat padding-box;
        width: 20px;
        height: 20px;
      }
    }
  }

  .v-main__wrap {
    background-color: #3D3D3F;
    z-index: 1;
  }

  .container--fluid {
    background-color: white;
    height: 100%;
  }

  .v-application {
    font-family: 'Poppins', sans-serif;
  }

  .router-link-active {
    .main-button {
      // background: transparent url('./assets/login.svg') 0% 0% no-repeat padding-box !important;
      background-repeat :no-repeat !important;
      background-image: url('./assets/login.svg') !important;
      background-size: cover !important;
      border-radius: 10px;
      opacity: 1;
      width: 220px;
      height: 55px;
      color: white !important;

      .messages-square-sidebar {
        border: 1px solid white;
        border-radius: 5px;
        padding: 1px 7px;;
        color: #D20F6E;
        font-size: 12px;
        background-color: white;
      }

      .documents-icon {
        background: transparent url('./assets/icons/document-icon.svg') 0% 0% no-repeat padding-box;
        width: 20px;
        height: 20px;
      }

      .messages-icon {
        background: transparent url('./assets/icons/message-icon.svg') 0% 0% no-repeat padding-box;
        width: 20px;
        height: 20px;
      }

      .logout-icon {
        background: transparent url('./assets/icons/logout-icon.svg') 0% 0% no-repeat padding-box;
        width: 20px;
        height: 20px;
      }

      .contact-icon {
        background: transparent url('./assets/icons/contact-icon.svg') 0% 0% no-repeat padding-box;
        width: 20px;
        height: 20px;
      }
    }

    .icon {
      color: white !important;
    }
  }

  .main-button {
    background: transparent url('./assets/login.svg') 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 220px;
    height: 55px;
    color: white !important;
  }

  .main-button:disabled,
  .main-button[disabled]{
    background: none !important;
    background-color: #cccccc !important;
    color: #666666 !important;
    border-radius: 10px;
    cursor: not-allowed;
  }

  .main-button:hover {
    background: #D20F6E;
    border-radius: 10px;
    opacity: 1;
    width: 220px;
    height: 55px;
    color: white;
  }

  .grey-link {
    margin-top: 15px;

    a {
      color: #b4b4b4 !important;
      text-decoration: none;
    }

    a:hover {
      color: #D20F6E !important;
      text-decoration: none;
    }
  }

  header {
    margin-top: 24px !important;
    border-radius: 20px 0 0 0 !important;
    background-color: #f7f7f7 !important;
    box-shadow: none !important;
  }

  .v-toolbar__content, .v-toolbar__extension {
    display: grid !important;
  }

  .fixed-row {
    height: 50px;
    width: 100%;
    top: 0;
    position: fixed;
    z-index: 5;
    background-color: white;
  }

  .fixed-row-with-logo {
    height: 80px;
    width: 100%;
    top: -12px;
    position: fixed;
    z-index: 5;
    background-color: white;
    border-bottom: 2px solid #e0e0e0;
  }

  .outlined__input {
    margin-bottom: -30px !important;

    fieldset {
      color: #B4B4B4 !important;
      border-color: #B4B4B4 !important;
    }

    .v-input__slot {
      background-color: white !important;
      border-color: #B4B4B4;
      border-radius: 10px;
    }
  }

  th {
    color: #B4B4B4 !important;
    border: none !important;
    font-weight: 100;
  }

  tbody {
    tr {
      height: 58px !important;

      .see-more {
        color: #d2116e !important;
        text-decoration: none;
      }

      .arrow-icon {
        color: #d2116e !important;
      }
    }

    td {
      border: none !important;
    }

    tr:hover {
      background-color: white !important;
      border-radius: 10px !important;

      .see-more {
        color: rgba(0, 0, 0, 0.54) !important;
      }

      .arrow-icon {
        margin-right: 8px !important;
        margin-left: 0px !important;
        color: rgba(0, 0, 0, 0.54) !important;
      }
    }
  }

  .orange-square {
    border: 1px solid #F57921;
    border-radius: 10px;
    padding: 14px 20px;
    color: #F57921;
  }

  .grey-square {
    border: 1px solid #cdcdcd;
    border-radius: 10px;
    padding: 14px 20px;
    color: #cdcdcd;
  }

  .yellow-color-square {
    border: 1px solid #f29123 !important;
    color: #f29123 !important;
    background-color: #f29123 !important;
  }

  .orange-color-square {
    border: 1px solid #F57921 !important;
    color: #F57921 !important;
    background-color: #F57921 !important;
  }

  .purple-color-square {
    border: 1px solid #d2116e !important;
    color: #d2116e !important;
    background-color: #d2116e !important;
  }

  .gradient-color-square {
    border: 1px solid #d2116e !important;
    color: #d2116e !important;
    background: transparent linear-gradient(270deg, #D20F6E 0%, #F57921 101.54%) 0% 0% no-repeat padding-box;
  }

  .pointer-mouse {
    cursor: pointer;
  }

  .year_h4 {
    color: #707070;
    font-size: 24px;
    font-weight: 600;

    @media only screen and (max-width: 1263px) {
      font-size: 16px;
    }
  }

  .product_h4 {
    color: #707070;
    font-size: 24px;
    font-weight: 600;

    @media only screen and (max-width: 1263px) {
      font-size: 16px;
    }
  }

  .symbol_div {
    font-size: 16px;
    color: #B4B4B4;
    padding-top: 6px;

    @media only screen and (max-width: 1263px) {
      font-size: 10px;
    }
  }

  .back-icon {
    background: transparent url('./assets/icons/back-icon.svg') 0% 0% no-repeat padding-box;
    width: 48px;
    height: 48px;
  }

  .close-icon {
    color: #b4b4b4 !important;
  }

  .close-icon:hover {
    color: #D20F6E !important;
  }

  .semibold-font {
    font-weight: 600;
  }

  .regular-font {
    font-weight: 400;
  }

  .close-menu {
    margin-right: -40px;
  }

  .v-dialog {
    border-radius: 20px !important;
    max-width: 600px !important;

    .v-card {
      padding: 0px 30px 10px !important;
    }

    .description {
      font-size: 14px;
      line-height: 1.8;
      color: #707070;
      word-break: break-word;
    }
  }

  .icone-circle {
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0px 0px 2px #888;
    padding: 0.5em 0.6em;
    background-color: white;
  }

  .dont-break {
    word-break: break-word
  }

  @media only screen and (max-width: 1263px) {
    header {
      height: 401px !important;
      transform: translateY(40px) !important;
    }

    .message-title {
      font-size: 24px;
    }

    .v-expansion-panel-header--active {
      border-bottom: 1px solid #F7F7F7;
    }

    .v-main__wrap {
      border-radius: 20px 20px 0 0;

      .container--fluid {
        border-radius: 20px 20px 0 0;
      }
    }

    .v-dialog .v-card {
      padding: 0px 5px 5px !important;

      .v-card__title {
        margin-top: -20px;
      }

      .v-card__text {
        .container {
          padding: 0 !important;
        }
      }
    }

    .v-badge--overlap {
      height: 100% !important;
      margin-left: 25px;
      margin-top: 12px;
    }

    .mobile-nav-more {
      background: white !important;
      min-width: 10px !important;
    }

    .v-btn--active {
      background: transparent url('./assets/mobile-nav-active-bg.svg') 0% 0% no-repeat padding-box !important;

      &.mobile-nav-more {
        background: white !important;
        min-width: 10px !important;
      }

      .v-badge--overlap {
        height: 100% !important;
        margin-left: 25px;
        margin-top: -5px;
        .v-badge__wrapper span {
          background-color: white !important;
        }
      }

      &.documents-icon .v-btn__content {
        background: transparent url('./assets/icons/document-icon-white-mobile-navigation.svg') 0% 0% no-repeat padding-box !important;
        background-position: 7px !important;
        width: 28px;
        height: 28px;
      }

      &.message-icon .v-btn__content {
        background: transparent url('./assets/icons/message-icon-white-mobile-navigation.svg') 0% 0% no-repeat padding-box !important;
        background-position: 7px !important;
        width: 28px;
        height: 28px !important;
      }

      &.contact-icon .v-btn__content {
        background: transparent url('./assets/icons/contact-icon-white-mobile-navigation.svg') 0% 0% no-repeat padding-box !important;
        background-position: 7px !important;
        width: 28px;
        height: 28px;
      }

      &.profile-icon .v-btn__content {
        background: transparent url('./assets/icons/profile-icon-white-mobile-navigation.svg') 0% 0% no-repeat padding-box !important;
        background-position: 7px !important;
        width: 28px;
        height: 28px;
      }
    }

    .theme--light.v-btn:before {
      opacity: 0 !important;
    }

    .document-status {
      min-height: 0px !important;

      @media only screen and (max-width: 1263px) {
        h1 {
          font-size: 24px;
        }
      }
    }

    .year {
      height: 61px !important;
      width: 61px !important;

      span {
        font-size: 17px !important;
      }
    }

    .documents-icon .v-btn__content {
      background: transparent url('./assets/icons/document-icon-black-mobile-navigation.svg') 0% 0% no-repeat padding-box;
      background-position: 7px;
      width: 28px;
      height: 28px;
    }

    .message-square {
      font-size: 10px;
      padding: 15px 5px;
    }

    .message-icon .v-btn__content {
      background: transparent url('./assets/icons/message-icon-black-mobile-navigation.svg') 0% 0% no-repeat padding-box;
      background-position: 7px;
      width: 28px;
      height: 28px;
    }

    .contact-icon .v-btn__content {
      background: transparent url('./assets/icons/contact-icon-black-mobile-navigation.svg') 0% 0% no-repeat padding-box;
      background-position: 7px;
      width: 28px;
      height: 28px;
    }

    .profile-icon .v-btn__content {
      background: transparent url('./assets/icons/profile-icon-black-mobile-navigation.svg') 0% 0% no-repeat padding-box;
      background-position: 7px;
      width: 28px;
      height: 28px;
    }

    .mobile-nav::before {
      // margin-top: 15px;
      // padding-left: 15px;
    }
  }

  .short-header {
    max-width: 50% !important;
  }

  .grey-text {
    color: #B4B4B4;
    font-size: 12px;
  }

  .circle-gradient-big {
    background: transparent url('./assets/circle-gradient.svg') 0% 0% no-repeat padding-box;
    display: inline-block;
    width: 164px;
    height: 164px;
    background-size: 164px 164px;

    i {
      margin-top: 30%;
      color: white;
      font-size: 64px;
    }
  }

  @media only screen and (max-width: 400px) {
    .pa-xxs-1 {
      padding: 4px !important;
    }

    .v-item-group.v-bottom-navigation .v-btn {
      min-width: 55px !important;
      padding: 2px;
    }

    .v-btn--active {
      background-size: 55px 55px !important;
      padding-top: 0;
    }

    .v-bottom-navigation {
      .mobile-nav {
        padding-left: 6px !important;
      }
    }

    .v-item-group.v-bottom-navigation .v-btn {
      min-width: 48px !important;
    }
  }

  .v-item-group.v-bottom-navigation .offer-button {
    @media only screen and (max-width: 306px) {
      margin-left: -7px;
    }

    @media only screen and (max-width: 283px) {
      margin-left: -11px;
    }
  }

  .v-item-group.v-bottom-navigation .mobile-nav-more {
    @media only screen and (max-width: 298px) {
      margin-right: -15px;
    }
  }

  .v-item-group.v-bottom-navigation .v-btn {
    @media only screen and (max-width: 380px) {
      min-width: 54px !important;
    }
    @media only screen and (min-width: 381px) {
      min-width: 65px !important;
    }
  }

  .v-item-group.v-bottom-navigation .v-btn .v-btn__content {
    @media only screen and (max-width: 768px) {
      margin-right: 3px;
    }
  }

  .v-item-group.v-bottom-navigation .offer-button {
    width: 54px !important;
    border-radius: 10px !important;

    @media only screen and (max-width: 768px) {
      margin-right: 2px;
    }

    .offer-icon {
      background: transparent url('./assets/icons/exclamation-mark-icon.svg') 0% 0% no-repeat padding-box;
      width: 26px;
      height: 26px;
      margin-top: 5px;
    }
  }

  .margin-bottom-mobile-160 {
    @media only screen and (max-width: 1263px) {
      margin-bottom: 160px;
    }
  }

  .v-btn--icon.v-size--default .v-icon {
    font-size: 42px !important;
    color: black;
  }

  #app > div.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
    top: 80px !important;
    left: 0px !important;
    width: 90vw !important;
  }

  .mobile-menu-item {
    display: inline-grid;
    margin-bottom: -5px !important;
  }

  .grey-background {
    border-radius: 20px;
    background: rgba(180, 180, 180, 0.15);
  }

  .inline-block {
    display: inline-block !important
  }
</style>
