import Vue from 'vue'

const state = {
  loadingInfo: false,
  specialOffer: null,
  currentDocuments: [],
  creatingDocumentState: null
}

const getters = {
  loadingInfo (state) {
    return state.loadingInfo
  },
  specialOffer (state) {
    return state.specialOffer
  },
  currentDocuments (state) {
    return state.currentDocuments
  },
  creatingDocumentState (state) {
    return state.creatingDocumentState
  }
}

const mutations = {
  getInfo (state, documentId, currentState) {
    state.loadingInfo = true
    Vue.axios.get('/info')
      .then(request => {
        if (request.data.data.attributes === null) {
          return
        }
        state.specialOffer = request.data.data.attributes.special_offer
        state.currentDocuments = request.data.data.attributes.current_documents
        if (documentId) {
          state.creatingDocumentState = request.data.data.attributes.current_documents.find(document => document.document_id === documentId)
        } else {
          state.creatingDocumentState = request.data.data.attributes.creating_document_state
        }
        state.loadingInfo = false
      })
  }
}

const actions = {
  getInfo ({ commit }, documentId, currentState) {
    commit('getInfo', documentId, currentState)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
