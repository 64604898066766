import Client from '@/models/Client'
import Vue from 'vue'
import { setOnesignalUserId, analyticsModule } from 'cuf-expo-bridge'

const state = {
  client: null
}

const mutations = {
  login (state, client) {
    state.client = client
  },

  logout (state) {
    state.client = null
  },

  changeLocale (state, locale) {
    state.client.locale = locale
  },

  setInstalledApp (state) {
    state.client.installed_app = true
  }
}

const getters = {
  currentClient (state) {
    return state.client
  }
}

const actions = {
  login ({ commit }, token) {
    const client = Client.from(token)
    commit('login', client)
    Vue.axios.defaults.headers.common.Authorization = 'Bearer ' + token
    if (client) {
      setOnesignalUserId(client.id)
      analyticsModule.logEvent({ name: 'login', params: { method: 'email' } })
      analyticsModule.setUserId(client.id)
    }
  },

  logout ({ commit }) {
    setOnesignalUserId(undefined)
    commit('logout')
  },

  setInstalledApp ({ commit }) {
    commit('setInstalledApp')
  },

  changeLocale ({ commit }, locale) {
    commit('changeLocale', locale)
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
