import JwtDecode from 'jwt-decode'

export default class Client {
  static from (token) {
    try {
      const obj = JwtDecode(token)
      return new Client(obj)
    } catch (_) {
      return null
    }
  }

  constructor ({ client_id, login, reset_password, locale, registered, accept_terms, filled_marketing_consent_date, agreement_with, installed_app }) { // eslint-disable-line camelcase
    this.id = client_id // eslint-disable-line camelcase
    this.login = login
    this.reset_password = reset_password // eslint-disable-line camelcase
    this.accept_terms = accept_terms // eslint-disable-line camelcase
    this.locale = locale
    this.registered = registered
    this.filled_marketing_consent_date = filled_marketing_consent_date // eslint-disable-line camelcase
    this.agreement_with = agreement_with // eslint-disable-line camelcase
    this.installed_app = installed_app // eslint-disable-line camelcase
  }
}
