import Vue from 'vue'

const state = {
  messages: [],
  message: null,
  messagesLoading: true,
  unreadMessagesSize: 0
}

const getters = {
  messages (state) {
    return state.messages
  },
  message (state) {
    return state.message
  },
  messagesLoading (state) {
    return state.messagesLoading
  },
  messageLoading (state) {
    return state.messageLoading
  },
  getMessage ({ commit }, messageId) {
    commit('getMessage', messageId)
  },
  unreadMessagesSize (state) {
    return state.unreadMessagesSize
  }
}

const mutations = {
  getMessages (state) {
    state.messagesLoading = true

    Vue.axios.get('/messages')
      .then(request => {
        let counter = 0
        const data = []
        request.data.data.map(message => {
          if (counter === 3) {
            counter = 1
          } else {
            counter += 1
          }
          Vue.set(message, 'counter', counter)
          data.push(message)
        })
        state.messages = data
        state.messagesLoading = false
        state.unreadMessagesSize = request.data.data.filter(message => !message.attributes.read).length
      })
  },
  getMessage (state, messageId) {
    state.message = null
    state.messageLoading = true

    Vue.axios.get('/messages/' + messageId)
      .then(request => {
        state.message = request.data.data
        state.messageLoading = false
      })
  },
  setAsReadMessage (state, messageId) {
    if (state.messages) {
      const filteredMessage = state.messages.find((message) => message.id === messageId)

      if (filteredMessage) {
        filteredMessage.attributes.read = true
        state.unreadMessagesSize = state.messages.filter(message => !message.attributes.read).length
      }
    }
  }
}

const actions = {
  getMessages ({ commit }) {
    commit('getMessages')
  },
  getMessage ({ commit }, messageId) {
    commit('getMessage', messageId)
  },
  setAsReadMessage ({ commit }, messageId) {
    commit('setAsReadMessage', messageId)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
