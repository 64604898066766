<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" :fullscreen="isScreenSmall ? true : false" max-width="1200px">
      <v-card>
        <v-card-title>
          <div class="container text-right">
            <v-menu
                bottom
                right
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="close-menu"
                  @click="cancel()"
                >
                  <v-icon class="close-icon mt-6 mr-6 mt-md-0 mr-md-0">mdi-close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </div>
          <h2 v-if="!successScreen">{{ $t('updateContactDetails') }}</h2>
          <h3 class="mt-4" v-if="!successScreen">{{ $t('mailingAddress') }}</h3>
          <p class='description mt-4' v-if="!successScreen" v-html="$t('updateContactDetailsInfo')"></p>
        </v-card-title>
        <v-card-text v-if="successScreen">
          <div class="text-center">
            <img src='@/assets/success-message.svg' />
            <h3 class="semibold-font mt-6">{{ $t('update_account_title') }}</h3>
            <p>{{ $t('update_account_subtitle') }}</p>
            <v-row class="mt-6">
              <v-col cols="1" class="mt-6">
                <span><img src='@/assets/icons/i-icon.svg' /></span>
              </v-col>
              <v-col cols="11 grey-text">
                <span v-html="$t('update_contact_info')"></span>
              </v-col>
            </v-row>
            <button
              class="main-button main-button-fixed mt-6"
              @click="cancel()"
            >{{ $t('back_to_panel') }}</button>
          </div>
        </v-card-text>
        <v-card-text v-if="!successScreen">
          <v-form @submit.prevent class="modal-form">
            <v-container>
              <v-row>
                <v-col cols="12" class="mt-1 mt-lg-0">
                  <v-text-field
                    v-model="object.attributes.email"
                    label="Email"
                    class="outlined__input"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="object.attributes.phone"
                    :label="$t('phone')"
                    class="outlined__input"
                    outlined
                    required
                  ></v-text-field>
                  <div class='error-notice' v-if="!validPhone">
                    {{ $t('wrongPhone') }}
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="object.attributes.correspondence_street"
                    :label="$t('street')"
                    class="outlined__input"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="object.attributes.correspondence_house_number"
                    :label="$t('house_number')"
                    class="outlined__input"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="object.attributes.correspondence_apartment_number"
                    :label="$t('apartment_number')"
                    class="outlined__input"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="object.attributes.correspondence_postcode"
                    :label="$t('postcode')"
                    class="outlined__input"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-text-field
                    v-model="object.attributes.correspondence_city"
                    :label="$t('city')"
                    class="outlined__input"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="object.attributes.correspondence_country"
                    :items="countries"
                    :label="$t('home_country')"
                    class="outlined__input"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions v-if="!successScreen">
          <v-spacer></v-spacer>
          <v-col sm="6" class="text-center">
            <button
              :disabled="!validEmail || sameCurrentData"
              class="main-button main-button-fixed"
              @click="updateContactDetails()"
            >{{ $t('update_data') }}</button>
          </v-col>
          <v-col sm="6" class="text-right grey-link">
            <a
              @click="cancel()"
            > {{ $t('cancel') }}
            </a>
          </v-col>
        </v-card-actions>
        <v-container v-if="!successScreen">
          <v-card-text>
            <v-row>
              <v-col sm="12">
                <h2 class="mt-4">{{ $t('mainAddress') }}</h2>
                <p class='description mt-4' v-html="$t('mainAddressInfo')"></p>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="object.attributes.street"
                  :label="$t('street')"
                  class="outlined__input"
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="object.attributes.house_number"
                  :label="$t('house_number')"
                  class="outlined__input"
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="object.attributes.apartment_number"
                  :label="$t('apartment_number')"
                  class="outlined__input"
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="object.attributes.postcode"
                  :label="$t('postcode')"
                  class="outlined__input"
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  v-model="object.attributes.city"
                  :label="$t('city')"
                  class="outlined__input"
                  outlined
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="object.attributes.country"
                  :items="countries"
                  :label="$t('country')"
                  class="outlined__input"
                  outlined
                  disabled
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'updateContactDetailsModal',
  data () {
    return {
      dialog: true,
      successScreen: false,
      object: {
        attributes: {
          email: null,
          phone: null,
          street: null,
          house_number: null,
          apartment_number: null,
          correspondence_country: null,
          correspondence_city: null,
          correspondence_street: null,
          correspondence_house_number: null,
          correspondence_postcode: null,
          correspondence_apartment_number: null
        }
      },
      currentObject: {
        attributes: {
          email: null,
          phone: null,
          street: null,
          house_number: null,
          apartment_number: null,
          correspondence_country: null,
          correspondence_city: null,
          correspondence_street: null,
          correspondence_house_number: null,
          correspondence_postcode: null,
          correspondence_apartment_number: null
        }
      },
      countries: [
        { value: 'Austria', text: this.$i18n.t('Austria') },
        { value: 'Belgium', text: this.$i18n.t('Belgium') },
        { value: 'Bulgaria', text: this.$i18n.t('Bulgaria') },
        { value: 'Denmark', text: this.$i18n.t('Denmark') },
        { value: 'France', text: this.$i18n.t('France') },
        { value: 'Netherlands', text: this.$i18n.t('Netherlands') },
        { value: 'Ireland', text: this.$i18n.t('Ireland') },
        { value: 'Germany', text: this.$i18n.t('Germany') },
        { value: 'Norway', text: this.$i18n.t('Norway') },
        { value: 'Poland', text: this.$i18n.t('Poland') },
        { value: 'Romania', text: this.$i18n.t('Romania') },
        { value: 'Sweden', text: this.$i18n.t('Sweden') },
        { value: 'United Kingdom', text: this.$i18n.t('United_Kingdom') }
      ]
    }
  },
  computed: {
    ...mapGetters(['currentClient']),

    isScreenSmall () {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
    },

    validPhone () {
      if (this.object.attributes.phone) {
        const phoneRegex = /^[+][0-9]*$/
        return !!this.object.attributes.phone.match(phoneRegex)
      } else {
        return false
      }
    },

    validEmail () {
      if (this.object.attributes.email) {
        const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        return !!this.object.attributes.email.match(emailRegex)
      } else {
        return false
      }
    },

    sameCurrentData () {
      return this.object.attributes.email === this.currentObject.attributes.email &&
        this.object.attributes.phone === this.currentObject.attributes.phone &&
        this.object.attributes.correspondence_street === this.currentObject.attributes.correspondence_street &&
        this.object.attributes.correspondence_house_number === this.currentObject.attributes.correspondence_house_number &&
        this.object.attributes.correspondence_apartment_number === this.currentObject.attributes.correspondence_apartment_number &&
        this.object.attributes.correspondence_country === this.currentObject.attributes.correspondence_country
    }
  },
  created () {
    this.$http.get(`client_accounts/${this.currentClient.id}/client_details`)
      .then(response => {
        this.object.attributes = response.data.attributes
        this.currentObject.attributes = JSON.parse(JSON.stringify(response.data.attributes))
      })
  },
  methods: {
    updateContactDetails () {
      this.$http.post(`/client_accounts/${this.currentClient.id}/update_contact_details/`, { data: this.object.attributes })
        .then(response => {
          this.successScreen = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    cancel () {
      this.$store.dispatch('closeShowUpdateContactDetailsModal')
    }
  }
}
</script>

<style lang="scss" scoped>
  .error-notice {
    font-size: 80%;
    color: red;
  }

  .success-notice {
    font-size: 80%;
    color: green;
  }
</style>
