import Vue from 'vue'

const state = {
  documents: [],
  document: null,
  creationDocument: null,
  documentsLoading: true
}

const getters = {
  documents (state) {
    return state.documents
  },
  document (state) {
    return state.document
  },
  documentsLoading (state) {
    return state.documentsLoading
  },
  documentLoading (state) {
    return state.documentLoading
  },
  getDocument ({ commit }, documentId) {
    commit('getDocument', documentId)
  },
  creationDocument (state) {
    return state.creationDocument
  }
}

const mutations = {
  getDocuments (state) {
    state.documentsLoading = true

    Vue.axios.get('/documents')
      .then(request => {
        let counter = 0
        const data = []
        request.data.data.map(document => {
          if (counter === 3) {
            counter = 1
          } else {
            counter += 1
          }
          Vue.set(document, 'counter', counter)
          data.push(document)
        })
        state.documents = data
        state.documentsLoading = false
      })
  },
  getDocument (state, documentId) {
    state.documentsLoading = true

    Vue.axios.get('/documents/' + documentId)
      .then(request => {
        state.document = request.data.data
        state.documentsLoading = false
      })
  },
  getCreationDocument (state, documentId) {
    state.documentsLoading = true

    Vue.axios.get('/documents/' + documentId + '/creation_show')
      .then(request => {
        state.creationDocument = request.data.data
        state.documentsLoading = false
      })
  }
}

const actions = {
  getDocuments ({ commit }) {
    commit('getDocuments')
  },
  getDocument ({ commit }, documentId) {
    commit('getDocument', documentId)
  },
  getCreationDocument ({ commit }, documentId) {
    commit('getCreationDocument', documentId)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
