<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" :fullscreen="isScreenSmall ? true : false" max-width="1200px">
      <v-card>
        <v-card-title>
          <div class="container text-right">
            <v-menu
                bottom
                right
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="close-menu"
                  @click="cancel()"
                >
                  <v-icon class="close-icon mt-6 mr-6 mt-md-0 mr-md-0">mdi-close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </div>
          <h2 class="semibold-font" v-if="!successScreen">{{ $t('changeBankAccount') }}</h2>
          <p class='description mt-4' v-if="!successScreen">{{ $t('updateAccountNumberInfo') }}</p>
        </v-card-title>
        <v-card-text v-if="successScreen">
          <div class="text-center">
            <img src='@/assets/success-message.svg' />
            <h3 class="semibold-font mt-6">{{ $t('update_account_title') }}</h3>
            <p>{{ $t('update_account_subtitle') }}</p>
            <v-row class="mt-6">
              <v-col cols="1" class="mt-2">
                <span><img src='@/assets/icons/i-icon.svg' /></span>
              </v-col>
              <v-col cols="11 grey-text">
                <span>{{ $t('update_account_info') }}</span>
              </v-col>
            </v-row>
            <button
              class="main-button main-button-fixed mt-6"
              @click="cancel()"
            >{{ $t('back_to_panel') }}</button>
          </div>
        </v-card-text>
        <v-card-text v-if="!successScreen">
          <v-progress-circular
            v-if="loading"
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <h2 v-if="!loading && filteredDocuments.length == 0">{{ $t('we_dont_handle_any_case') }}</h2>
          <v-form v-if="!loading && filteredDocuments.length > 0" @submit.prevent class="modal-form">
            <v-container>
              <v-select
                @change="changeDocument"
                v-model="selectedDocument"
                :items="filteredDocuments"
                class="outlined__input"
                outlined
                :label="$t('select_document')"
              ></v-select>
              <v-row>
                <v-progress-circular
                  v-if="loadingDocument"
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                <v-col cols="12">
                  <v-text-field
                    :disabled="!updateAccountPermissions"
                    v-model="object.attributes.account_number"
                    @input="removeSpaces()"
                    :label="$t('accountNumber')"
                    class="outlined__input"
                    outlined
                    required
                  ></v-text-field>
                  <div class='error-notice' v-if="!validBankAccount">
                    {{ WrongAccountNumberTranslate }}
                  </div>
                  <div class='success-notice' v-if="validBankAccount && this.object.attributes.account_number">
                    {{ $t("correct_account") }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    :disabled="!updateAccountPermissions"
                    @change="setAccountCurrency"
                    v-model="object.attributes.payment_method"
                    :items="items"
                    class="outlined__input"
                    outlined
                    :label="$t('payment_method')"
                  ></v-select>
                  <div class='success-notice' v-if="not_allow_change_currency_info">
                    {{ $t('not_allow_change_currency_info') }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :disabled="!updateAccountPermissions"
                    v-model="object.attributes.account_owner_first_name"
                    :label="$t('account_owner_first_name')"
                    class="outlined__input"
                    required
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :disabled="!updateAccountPermissions"
                    v-model="object.attributes.account_owner_last_name"
                    :label="$t('account_owner_last_name')"
                    class="outlined__input"
                    required
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="checkbox-row">
                <v-col cols="12">
                  <v-checkbox v-model="checkbox" :disabled="!updateAccountPermissions">
                    <template v-slot:label>
                      <div class="description">
                        {{ $t('updateAccountNumberInfoButton') }}
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions v-if="!successScreen" class="row">
          <v-spacer></v-spacer>
          <v-col cols="12" lg="6" class="text-center">
            <button
              :disabled="!checkbox || !validBankAccount || !object.attributes.account_owner_last_name || !object.attributes.account_owner_first_name"
              class="main-button main-button-fixed"
              @click="updateAccountNumber()"
            >{{ $t('update_data') }}</button>
          </v-col>
          <v-col cols="12" lg="6" class="text-center text-lg-right grey-link">
            <a
              @click="cancel()"
            > {{ $t('cancel') }}
            </a>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'updateAccountNumberModal',
  data () {
    return {
      dialog: true,
      checkbox: false,
      successScreen: false,
      object: {
        attributes: {
          account_number: null,
          account_owner_first_name: null,
          account_owner_last_name: null,
          payment_method: null
        }
      },
      payment_method: null,
      selectedDocument: null,
      documents: [],
      loading: true,
      updateAccountPermissions: null,
      loadingDocument: false
    }
  },
  computed: {
    isScreenSmall () {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
    },

    filteredDocuments () {
      const list = []
      const filterDocuments = this.documents.filter(document => document.attributes.can_change_account)
      filterDocuments.map(document => list.push({ value: document.id, text: document.attributes.symbol }))

      return list
    },

    validBankAccount () {
      if (this.object.attributes.account_number === '' || this.object.attributes.account_number === null) {
        return true
      }
      const ibantools = require('ibantools')

      if (this.payment_method === 'PLN_transfer') {
        const iban = ibantools.electronicFormatIBAN('PL' + this.object.attributes.account_number)
        return ibantools.isValidIBAN(iban)
      } else if (this.payment_method === 'BGN_transfer') {
        return this.object.attributes.account_number.substring(0, 2) === 'BG' && ibantools.isValidIBAN(this.object.attributes.account_number)
      } else if (this.payment_method === 'RON_transfer') {
        return this.object.attributes.account_number.substring(0, 2) === 'RO' && ibantools.isValidIBAN(this.object.attributes.account_number)
      } else {
        return ibantools.isValidIBAN(this.object.attributes.account_number)
      }
    },
    not_allow_change_currency_info () {
      if (this.updateAccountPermissions) {
        return !this.updateAccountPermissions.can_change_payment_method
      } else {
        return false
      }
    },
    items () {
      if (this.updateAccountPermissions) {
        var list = []
        this.updateAccountPermissions.payment_methods.map(value => list.push({ value: value, text: this.getTranslation(value) }))
        return list
      } else {
        return []
      }
    },
    WrongAccountNumberTranslate () {
      switch (this.payment_method) {
        case 'PLN_transfer' || 'PLN_postal_order':
          return this.$t('PL_wrong_account_number')
        case 'RON_transfer' || 'RON_postal_order':
          return this.$t('RON_wrong_account_number')
        case 'BGN_transfer':
          return this.$t('BGN_wrong_account_number')
        case 'EUR_transfer' || 'GBP_transfer':
          return this.$t('EUR_GBP_wrong_account_number')
        default:
          return this.$t('PL_wrong_account_number')
      }
    }
  },
  created () {
    Vue.axios.get('/documents')
      .then(request => {
        this.documents = request.data.data
        this.loading = false
      })
  },
  methods: {
    changeDocument () {
      this.loadingDocument = true
      this.$http.get(`documents/${this.selectedDocument}/get_settlement_info`)
        .then(request => {
          this.payment_method = request.data.data.attributes.payment_method
          this.object.attributes.account_number = request.data.data.attributes.account_number
          this.object.attributes.payment_method = this.payment_method
          this.object.attributes.account_owner_first_name = request.data.data.attributes.account_owner_first_name
          this.object.attributes.account_owner_last_name = request.data.data.attributes.account_owner_last_name
        })

      this.$http.get(`documents/${this.selectedDocument}/update_account_permissions`)
        .then(request => {
          this.updateAccountPermissions = request.data
          this.loadingDocument = false
        })
    },
    removeSpaces () {
      if (this.object.attributes.payment_method !== 'PLN_transfer') {
        this.object.attributes.account_number = this.object.attributes.account_number.replace(/\s/g, '').toUpperCase()
      } else {
        this.object.attributes.account_number = this.object.attributes.account_number.toUpperCase()
      }
    },
    updateAccountNumber () {
      this.$http.put(`documents/${this.selectedDocument}/update_account_number`, { data: this.object })
        .then(response => {
          this.successScreen = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    cancel () {
      this.$store.dispatch('closeShowUpdateAccountNumberMultipleDocumentsModal')
    },
    getTranslation (value) {
      return this.$t(value)
    },
    setAccountCurrency () {
      this.payment_method = this.object.attributes.payment_method
      if (this.object.attributes.payment_method === 'PLN_transfer' || this.object.attributes.payment_method === 'PLN_postal_order') {
        this.object.attributes.account_currency = 'PLN'
      } else if (this.object.attributes.payment_method === 'RON_transfer' || this.object.attributes.payment_method === 'RON_postal_order') {
        this.object.attributes.account_currency = 'RON'
      } else if (this.object.attributes.payment_method === 'BGN_transfer') {
        this.object.attributes.account_currency = 'BGN'
      } else if (this.object.attributes.payment_method === 'EUR_transfer') {
        this.object.attributes.account_currency = 'EUR'
      } else if (this.object.attributes.payment_method === 'GBP_transfer') {
        this.object.attributes.account_currency = 'GBP'
      }
    }
  }
}
</script>

<style lang="scss">
  .error-notice {
    font-size: 80%;
    color: red;
  }

  .success-notice {
    font-size: 80%;
    color: green;
  }

  .checkbox-row {
    margin-top: -30px !important;
  }

  .v-list-item__content {
    margin-left: 5px;
  }
</style>
