import axios from 'axios'

/**
 * Service to check for application updates
 * and automatically refresh the page when a new version is detected
 */
class VersionChecker {
  constructor () {
    this.currentVersion = null
    this.currentHash = null
    this.checkInterval = null
    this.initialized = false
  }

  /**
   * Initialize the version checker
   * @param {Object} options Configuration options
   * @param {number} options.interval Check interval in milliseconds (default: 5 minutes)
   * @param {boolean} options.autoReload Whether to automatically reload when a new version is detected (default: true)
   * @param {Function} options.onUpdateDetected Callback function when a new version is detected
   */
  init (options = {}) {
    if (this.initialized) return

    // Default options
    const defaultOptions = {
      interval: 5 * 60 * 1000, // 5 minutes
      autoReload: true,
      onUpdateDetected: null
    }

    const config = { ...defaultOptions, ...options }

    // Get initial version
    this.fetchVersionInfo().then(versionInfo => {
      this.currentVersion = versionInfo.version
      this.currentHash = versionInfo.hash

      // Set up periodic checks
      this.checkInterval = setInterval(() => {
        this.checkForUpdates(config)
      }, config.interval)
    })

    this.initialized = true
  }

  /**
   * Check for updates
   * @param {Object} config Configuration options
   */
  checkForUpdates (config) {
    this.fetchVersionInfo()
      .then(versionInfo => {
        const hasNewVersion = versionInfo.hash !== this.currentHash

        if (hasNewVersion) {
          console.log(`New version detected: ${versionInfo.version} (${versionInfo.hash})`)

          // Call callback if provided
          if (typeof config.onUpdateDetected === 'function') {
            config.onUpdateDetected(versionInfo)
          }

          // Auto reload if enabled
          if (config.autoReload) {
            console.log('Reloading application to apply updates...')
            window.location.reload(true)
          }
        }
      })
      .catch(error => {
        console.error('Error checking for updates:', error)
      })
  }

  /**
   * Fetch version information from the server
   * @returns {Promise<Object>} Version information
   */
  fetchVersionInfo () {
    const timestamp = new Date().getTime() // Add timestamp to prevent caching
    return axios.get(`/version.json?t=${timestamp}`)
      .then(response => response.data)
      .catch(error => {
        console.error('Error fetching version info:', error)
        // Return current version as fallback
        return {
          version: this.currentVersion || '0.0.0',
          hash: this.currentHash || ''
        }
      })
  }

  /**
   * Stop checking for updates
   */
  stop () {
    if (this.checkInterval) {
      clearInterval(this.checkInterval)
      this.checkInterval = null
    }
  }
}

export default new VersionChecker()
